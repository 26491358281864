[dir='rtl'] {
  .del-header {
    .del-stripe {
      &__container {
        .swiper-button {
          &-prev,
          &-next {
            transform: rotate(180deg);
          }

          &-prev {
            right: 16px;
            left: auto;
          }

          &-next {
            left: 16px;
            right: auto;
          }
        }
      }

        &__link {
        @include bp('del-breakpoint-s') {
          border-right: none;
          border-left: 1px solid rgba($del-color-white, 0.2);

          &:last-child {
            border-left: none;
          }
        }
      }
    }

    .del-stripe {
      &__link {
        border-right: none;
        border-left: 1px solid rgba($del-color-white,0.2);

        &:last-child {
          border-left: none;
        }
      }
    }

    .del-btnIcon {
      &--account {
        &.hover,
        &:hover {
          .dln-drawer {
            left: -55px;
            right: auto;
          }
        }
      }

      .dln-drawer {
        .text {
          text-align: right;
        }
      }
    }

    .del-icon.icon-select-up {
      transform: rotate(-90deg);
    }

    /* Probably to be fixed when solving Swiper RTL problem */
    .del-imagecarousel__button.swiper-button-next {
      transform: rotate(90deg);
    }

    /* Probably to be fixed when solving Swiper RTL problem */
    .del-imagecarousel--triple {
      .del-carousel__button {
        &.swiper-button-prev {
          transform: rotate(0);
        }
        &.swiper-button-next {
          transform: rotate(180deg);
        }
      }
    }

    &__links-container {
      margin-left: 0;
      margin-right: 27px;
    }

    &__abovelinks {
      li {
        margin-left: 0;
        margin-right: 20px;
      }
    }

    &__button-container {
      .del-btnIcon {
        margin-left: 0;
        margin-right: 20px;
      }
    }

    .del-category__title {
      .icon-arrow-left {
        transform: rotate(180deg);
      }
    }

    .del-highlights {
      @include bp('del-breakpoint-s') {
        border: none;
        border-right: 3px solid $del-color-light-grey;
      }
    }

    .del-subcategory {
      .del-subcategory__highlights {
        @include bp('del-breakpoint-s') {
          border: none;
          border-right: 3px solid $del-color-light-grey;
        }
      }
    }
  }
}
