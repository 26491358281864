/* Keyframes */

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

/* Animations */

$del-animation-spinner: spinner 1.4s linear infinite;
