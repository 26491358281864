@mixin textgroup-subtitle--generic {
  @include del-font-demi;
  font-size: toRem(10px);
  letter-spacing: 1.04px;
  line-height: 8.32px;
  margin: 2px 0;

  @include bp('del-breakpoint-s') {
    font-size: toRem(13px);
    letter-spacing: 1.28px;
    line-height: 10.24px;
  }
}
@mixin textgroup-content--generic {
  font-size: toRem(21px);
  line-height: 27.3px;
  margin: 4px 0 12px;
  @include bp('del-breakpoint-s') {
    font-size: toRem(24px);
    line-height: 32.4px;
    margin: 7px 0 16px;
  }
  @include bp('del-breakpoint-l') {
    font-size: toRem(28px);
    line-height: 39.2px;
    margin: 7px 0 21px;
  }
}

.textgroup-subtitle--generic {
  @include textgroup-subtitle--generic;
}

.textgroup-content--generic {
  @include textgroup-content--generic;
}

@mixin textgroup--generic {
  .del-textgroup {
    &__subtitle {
      @extend .textgroup-subtitle--generic;
      p {
        @extend .textgroup-subtitle--generic;
      }
    }

    &__content {
      @extend .textgroup-content--generic;
      p {
        @extend .textgroup-content--generic;
      }
    }
  }
}
