@keyframes del-header-animation-to-white {
  from {}
  to {background-color: $del-color-white}
}

@keyframes del-header-animation-show {
  from {top: -93px}
  to {top: 0}
}

@keyframes del-header-animation-hide {
  from {top: 0}
  to {top: -93px}
}

@keyframes del-header-animation-categoryScreen-slide-down {
  from {top: -100%;}
  to {top: 0;}
}

@keyframes del-header-animation-categoryMenu-mobile-slide-down {
  from {height: 61px;}
  to {height: 100vh;}
}

.del-header-show-menu-mobile {
  animation: del-header-animation-categoryMenu-mobile-slide-down .3s ease;
}
