@mixin textgroup-subtitle--medium {
  font-size: toRem(11px);
  letter-spacing: 2.24px;
  margin: 3px 0;
}
@mixin textgroup-title--medium {
  @include del-font-medium;
  font-size: toRem(28px);
  letter-spacing: -0.42px;
  line-height: 29px;

  @include bp('del-breakpoint-s') {
    font-size: toRem(37px);
    letter-spacing: -0.555px;
    line-height: 37px;
  }

  @include bp('del-breakpoint-l') {
    font-size: toRem(50px);
    letter-spacing: -0.75px;
    line-height: 50px;
    margin: 6px 0;
  }
}
@mixin textgroup-content--medium {
  font-size: toRem(18px);
  line-height: 24px;
  margin: 5px 0 11px;
  @include bp('del-breakpoint-l') {
    font-size: toRem(21px);
    line-height: 26px;
    margin: 7px 0 14px;
  }
}

.textgroup-subtitle--medium {
  @include textgroup-subtitle--medium;
}

.textgroup-title--medium {
  @include textgroup-title--medium;
}

.textgroup-content--medium {
  @include textgroup-content--medium;
}

@mixin textgroup--medium {
  .del-textgroup {
    &__subtitle {
      @extend .textgroup-subtitle--medium;
      p {
        @extend .textgroup-subtitle--medium;
      }
    }

    &__title {
      @extend .textgroup-title--medium;
      p {
        @extend .textgroup-title--medium;
      }
    }

    &__content {
      @extend .textgroup-content--medium;
      p {
        @extend .textgroup-content--medium;
      }
    }
  }
}
