.del-beveragesslider {
  .swiper-container-wrapper {
    position: relative;
    margin-inline: auto;
    max-width: calc(100% - 40px);

    @include bp('del-breakpoint-s') {
      max-width: 700px;
    }

    @include bp('del-breakpoint-m') {
      max-width: 960px;
    }

    @include bp('del-breakpoint-xl') {
      max-width: 1375px;
    }
  }

  .swiper-container {
    margin-top: 150px;

    @include bp('del-breakpoint-s') {
      margin-top: 20px;
      margin-inline: auto;
      max-width: 600px;
    }

    @include bp('del-breakpoint-m') {
      max-width: 860px;
    }

    @include bp('del-breakpoint-xl') {
      margin-top: 40px;
      max-width: 1275px;
    }
  }

  .swiper-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include bp('del-breakpoint-s') {
      max-width: 50%;
    }
    @include bp('del-breakpoint-m') {
      max-width: 33%;
    }
    @include bp('del-breakpoint-xl') {
      max-width: 25%;
    }
  }

  .swiper-container-mask {
    position: absolute;
    background: rgba($del-color-white, 0.1);
    border-radius: 100%;
    filter: blur(30px);
    width: 100%;
    top: 50%;
    left: 50%;
    height: 50%;
    transform: translate(-50%, -50%);
  }

  .swiper-button {
    visibility: hidden;
    position: absolute;
    width: 40px;
    height: 40px;
    background-color: transparent;
    border: 1px solid $del-color-white;
    color: $del-color-white;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
  }

  .swiper-button-prev {
    left: 0;
    right: auto;
  }

  .swiper-button-next {
    right: 0;
    left: auto;
  }
}
