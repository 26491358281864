.del-product-moodboard {

  @include bp-range('del-breakpoint-s', 'del-breakpoint-max-m') {
    padding: 0 40px 40px 40px;
  }

  @include bp-range('del-breakpoint-m', 'del-breakpoint-max-l') {
    padding: 0 70px 45px 70px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include bp('del-breakpoint-m') {
      flex-direction: row;
    }

    &.--hidden {
      display: none;
    }

    &__item {
      @include bp('del-breakpoint-m') {
        width: calc(50% - 10px);
      }

      &.--primary {
        margin-bottom: 20px;
        @include bp('del-breakpoint-m') {
          margin-bottom: 0;
        }
      }

      &.--secondary {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        > .del-product-moodboard__wrapper__item__secondary-section {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          &:first-child {
            margin-bottom: 20px;
          }
        }
      }
    }

  }
}