.del-textgroup {
  &__appLinks {
    margin: 40px -10px 0;

    &-title {
      line-height: 24px;
      font-size: toRem(18px);
      font-weight: 500;
    }

    &-list {
      display: flex;
      margin-top: 10px;
      justify-content: center;
      flex-wrap: wrap;
    }

    &-link {
      list-style: none;
      margin: 8px;

      &-cta {
        display: block;

        img {
          display: block;
          width: 156px;
          height: auto;
          background: black;
          border-radius: 5px;
        }
      }
    }

    @include bp('del-breakpoint-l') {
      margin: 40px 0;

      &-list {
        display: flex;
        margin-top: 10px;
        justify-content: flex-start;
        flex-wrap: wrap;
      }

      &-link {
        margin: 8px 16px 0 0;
      }
    }
  }

  &--center {
    @include bp('del-breakpoint-l') {
      .del-textgroup__appLinks-list {
        justify-content: center !important;
      }
    }
  }
}
