.del-contentcards {
  &__card {
    border-top: 1px solid $del-color-grey;
    cursor: pointer;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    transition: flex-grow 1s;

    @include bp('del-breakpoint-l') {
      border-top: none;
      border-left: 1px solid $del-color-grey;
    }

    &:first-child {
      border: none;
    }

    &--open {
      flex-grow: 2;
      flex-shrink: 0;
      flex-basis: 0;
    }

    &--shrunk {
      flex-grow: 0.001;
      flex-shrink: 0;
      flex-basis: 0;

      @include bp('del-breakpoint-l') {
        width: 10%;
      }
    }
  }
}

[dir="rtl"] {
  .del-contentcards {
    &__card {
      @include bp('del-breakpoint-l') {
        border-left: none;
        border-right: 1px solid $del-color-grey;
      }
    }
  }
}
