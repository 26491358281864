.del-chemicalmixes {

  &__wrapper {
    background: $del-color-white-smoke;
    padding: 40px 0px;
  }

  &__flex-grid {
    display: flex;
    flex-direction: column;
    gap: 60px;
    margin: 0 auto;

    @include bp('del-breakpoint-xs') {
      margin: 0;
      padding: 0 14px;
    }

    @include bp('del-breakpoint-s') {
      max-width: 632px;
      margin: 0;
      padding: 0 14px;
    }

    @include bp('del-breakpoint-m') {
      max-width: 900px;
      margin: 0;
      padding: 0 24px;
    }

    @include bp('del-breakpoint-l') {
      margin: 0;
      padding: 0 36px;
    }

    @include bp('del-breakpoint-xl') {
      max-width: 1416px;
      margin: 0 auto;
      padding: 0;
    }
  }

  &__flex-grid_item {
    display: flex;
    flex-direction: row;
    gap: 20px;

    @include bp('del-breakpoint-s') {
      margin-inline: 16px;
    }

    @include bp('del-breakpoint-m') {
      margin-inline: 8px;
    }

    @include bp('del-breakpoint-xl') {
      margin-inline: 24px;

      .template-pages-plp-dlgDelonghiPLP & {
        margin-inline: 20px;
      }
    }
  }
}
