.del-chemicalmixes {

  &__image {
    width: 80px;
    height: auto;

    img {
        width: inherit;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-inline: 0px;

    @include del-font-book;
    font-size: REM(16px);
    line-height: REM(19px);
    color: var(--brand-06);

    @include bp('del-breakpoint-m') {
      font-size: REM(18px);
    }
  }

  &__title {
    color: $del-color-black;
    font-weight: bold !important;
  }

  &__description {
  }

  &__links a:not(:last-child) {
    margin-right: 10px;
  }
}
