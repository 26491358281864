.del-featureslist__features-desktop {
  display: none;
  @include bp('del-breakpoint-l') {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: calc(684px * 2);
    margin-inline: auto;

    &.--reverse {
      flex-direction: row-reverse;
    }

    &__images,
    &__features {
      width: 50%;
    }

    &__images {
      .swiper-slide {
        aspect-ratio: 684 / 684;
        max-width: 684px;
        img {
          max-width: 100%;
          display: block;
          object-fit: cover;
          height: auto;
        }
      }
    }

    &__features {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .del-featureslist__card {
        display: flex;
        flex-direction: column;
        gap: 14px;
        border-bottom: 1px solid $del-color-grey;
        padding-block: 54px;
        opacity: 0.4;
        transition: opacity 0.3s;
        cursor: pointer;
        width: 448px;
        margin-left: 20.90643275%;
        margin-right: 13.59649123%;

        &.--reverse {
          margin-left: 13.59649123%;
          margin-right: 20.90643275%;
        }

        &.--count-1 {
          cursor: default;
        }

        &.--active {
          opacity: 1;
        }

        &:last-child {
          border-bottom-color: transparent;
        }

        &-title {
          @include del-font-medium;
          font-size: REM(21px);
          color: $del-color-pure-black;
        }

        &-description {
          @include del-font-book;
          font-size: REM(16px);
          line-height: 1.19;
          color: $del-color-pure-black;
        }
      }
    }
  }
}
