.del-video-responsive {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    video {
        width: 100%;
        height: auto;
        max-height: 100vh;
        object-fit: cover;
    }

    &__mobile {
        display: none;

        @include bp('del-breakpoint-m', 'max') {
            display: block;
        }
    }

    &__desktop {
        display: none;

        @include bp('del-breakpoint-m') {
            display: block;
        }
    }
}