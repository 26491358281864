.del-basic-form {
  &__title {
    margin-bottom: 40px;
  }

  [data-screenset-element-id='gigya-newsletter-screen'] {
    .gigya-input-text {
      padding: 14px 8px 8px;
    }

    .dlg-gigya-button-wrapper input[type='submit'] {
      background-color: var(--brand-04);
    }

    .gigya-custom-checkbox {
      .gigya-input-checkbox {
        opacity: 0;

        + label {
          font-size: toRem(14px);

          &::before {
            width: 24px;
            height: 24px;
            border-radius: 5px;
          }

          &::after {
            background-color: transparent;
          }
        }

        &:checked {
          + label {
            &::after {
              content: 'à';
              font-family: var(--fontNameIcons);
            }

            .gigya-checkbox-text {
              color: var(--brand-04);
              font-weight: 400;
            }
          }
        }
      }

      .gigya-checkbox-text {
        font-size: toRem(14px);
        color: var(--brand-04);
      }
    }

    .gigya-toggle-content {
      a {
        color: var(--brand-02);
      }
    }

    #gigya-subscribe-with-email-form {
      > .gigya-layout-row {
        .gigya-layout-row:nth-child(5) {
          i {
            background: $del-color-pure-black;
            color: $del-color-white;
          }

          .gigya-toggle-content {
            color: var(--brand-02);
          }
        }
      }
    }
  }

  @media (min-width: 1024px) {
    #gigya-subscribe-with-email-form {
      > .gigya-layout-row {
        .gigya-layout-row {
          width: 20%;
        }
      }
    }
  }

  .subscription-checkbox {
    position: absolute;
    z-index: 8;
    opacity: 1 !important;
    width: 0;
    height: 0;

    + .subscription-name-label {
      &:before {
        position: relative;
        content: '';
        display: inline-block;
        border-radius: 0 !important;
        margin-right: 9px;
        margin-bottom: -1px;
        width: 20px;
        height: 20px;
        background-color: $del-color-none;
        display: inline-block;
        border: 1px solid var(--ui-02);
      }

      &:after {
        display: none;
      }

      i {
        background: $del-color-pure-black;
        width: 15px;
        height: 15px;
        display: inline-block;
        color: $del-color-white;
        border-radius: 50%;
        text-align: center;
        font-size: toRem(13px);
        font-weight: 700;
        position: relative;
        cursor: pointer;
      }
    }
  }

  .subscription-checkbox:checked:before {
    content: '\E0';
    font-family: var(--fontNameIcons);
    background-color: $del-color-none;
    transform: scale(1);
    position: absolute;
    left: 7px;
    top: 0;
  }
}
