.del-header {
  &__filler.del-header__subcategory {
    @include bp('del-breakpoint-m') {
      box-shadow: none;
      animation: none;
    }
  }

  .del-subcategory {
    &__body {
      width: 100%;
      height: unset;
      padding: 24px 0;

      @include bp('del-breakpoint-s') {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
      }

      @include bp('del-breakpoint-m') {
      }
    }

    .del-subcategory__column {
      width: 100%;

      @include bp('del-breakpoint-s') {
        width: 50%;
      }

      @include bp('del-breakpoint-m') {
        width: 25%;
      }
    }

    .del-subcategory__highlights {
      border-top: 3px solid $del-color-light-grey;
      padding-top: 24px;

      @include bp('del-breakpoint-s') {
        flex-grow: 3;
        border: none;
        border-left: 3px solid $del-color-light-grey;
        padding-top: 0;
      }
    }

    .del-highlights__title {
      .del-icon {
        width: 16px;
        height: 16px;
      }
    }

    .del-highlights__body {
      justify-content: flex-start;
      padding: 0 16px;
    }

    .del-recipelink {
      display: flex;
      flex-direction: column;
      width: 330px;
      margin: 0 12px 24px;
      padding: 12px;
      background-color: $del-color-light-grey;

      &__image {
        display: block;
        flex-grow: 0;
        width: 306px;
        height: 262px;
        background-color: grey;
      }

      &__stats {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;
        padding: 16px 12px 5px;
      }

      &__title {
        @include del-font-medium;
        font-size: toRem(21px);
        line-height: 24px;
        margin-bottom: 16px;
      }

      &__infos {
        display: flex;
        align-items: center;
        @include del-font-light;
        font-size: toRem(14px);
        line-height: 16px;
        color: $del-color-text-60;

        & > span {
          display: flex;
          align-items: center;
          flex: 1 0 auto;
        }

        .del-icon {
          $size: 24px;
          width: $size;
          height: $size;
        }

        &--text {
          margin: 0 5px;
        }
      }
    }

    .del-collectionslist {
      display: grid;
      justify-content: center;
      gap: 20px;

      @include bp('del-breakpoint-s') {
        justify-content: unset;
        padding-left: 40px;
        row-gap: 28px;
        grid-template-columns: 1fr 1fr;
      }

      @include bp('del-breakpoint-l') {
        display: none;
      }

      .--separator {
        display: none;

        @include bp('del-breakpoint-s') {
          display: block;
          grid-column: span 2;
          height: 1px;
          background: $del-color-light-grey;
          margin-inline: -100%;
        }

        @include bp('del-breakpoint-l') {
          display: none;
        }
      }

      &__slide {
        img {
          width: 330px;
          height: 200px;
          object-fit: cover;

          @include bp('del-breakpoint-s') {
            height: 330px;
          }
        }
      }
    }
  }
}
