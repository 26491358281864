.del-disclaimer,
.del-disclaimer p,
.del-disclaimer pre {
  @include del-font-book;
  width: 100%;
  max-width: none;
  margin: 0 auto;
  background-color: transparent;
  font-size: toRem(14px);
  line-height: 24px;
  color: $del-color-text-80;

  &--lite {
    color: $del-color-white;
  }
}
