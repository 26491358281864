.del-contentcards {
  &__link {
    display: none;
    align-items: flex-end;
    color: $del-color-white;
    @include del-font-medium;
    font-size: REM(16px);
    letter-spacing: normal;
    line-height: normal;
    margin-top: 27px;

    @include bp('del-breakpoint-m') {
      margin-top: 10px;
    }

    &,
    &:link,
    &:visited {
      text-decoration: none;
      color: $del-color-white;
    }

    .del-icon {
      width: 14px;
      height: 14px;
    }

    &:hover {
      .del-icon {
        margin-left: 5px;
      }
    }
  }
}
