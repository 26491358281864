.del-featureslist__features-mobile {
  @include bp('del-breakpoint-l') {
    display: none;
  }

  .swiper-wrapper {
    position: relative;
    transform: translateX(calc(16px - 8px));
    @include bp('del-breakpoint-s') {
      transform: translateX(calc(40px - 12px));
    }
    @include bp('del-breakpoint-m') {
      transform: translateX(calc(92px - 12px));
    }

    &.--count-1 {
      justify-content: center;
      transform: translateX(0) !important;
    }

    &.--count-2 {
      @media (min-width: 576px) {
        // tweekpoint
        justify-content: center;
        transform: translateX(0) !important;
      }
    }

    &.--count-3 {
      @media (min-width: 920px) {
        // tweekpoint
        justify-content: center;
        transform: translateX(0) !important;
      }
    }
  }

  .swiper-slide {
    overflow: hidden;
    max-width: 280px;
    padding-inline: 8px;

    @include bp('del-breakpoint-s') {
      max-width: 288px;
      padding-inline: 12px;
    }

    .del-featureslist__image-wrapper {
      aspect-ratio: 264 / 264;
      img {
        max-width: 100%;
        display: block;
        object-fit: cover;
        height: auto;
      }
    }
  }

  .del-featureslist__card-texts {
    display: flex;
    flex-direction: column;
    gap: 13px;
    margin-top: 17px;
    padding-left: 16px;
  }

  .del-featureslist__card-title {
    @include del-font-medium;
    font-size: REM(21px);
    color: $del-color-pure-black;
  }

  .del-featureslist__card-description {
    @include del-font-book;
    font-size: REM(16px);
    line-height: 1.19;
    color: $del-color-text;
  }
}
