@include bp('del-breakpoint-xs') {
  .del-stories {
    &__container {
      padding: 0 16px;
    }

    &__wrapper {
      width: 100%;
      // margin: 0 20px;
      padding-top: 150%;
    }

    &__screen {
      height: 100%;
    }
  }

  .swiper-container.stories-slider {
    .swiper-slide {
      &.topAligned {
        align-items: flex-end;
      }

      .del-stories__slide--content {
        left: 20px;

        margin: 50px auto;
        max-width: calc(100% - 20px);

        opacity: 0.85;
      }

      .del-stories__slide--visual {
        .mobileVersion {
          display: block;
          width: 100%;
          height: 100%;
        }

        .desktopVersion {
          display: none;
        }
      }
    }

    .swiper-footer {
      .swiper-button-next,
      .swiper-button-prev {
        display: none;
      }
    }

    .swiper-pagination {
      bottom: 20px;
      width: calc(100% - 20px);
    }
  }
}
