.del-contentcards {
  .del-textgroup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 16px;

    @include bp('del-breakpoint-max-l', 'max') {
      text-align: left;
    }

    @include bp('del-breakpoint-l') {
      justify-content: flex-start;
      align-items: center;
      font-size: REM(37px);
      letter-spacing: -0.7px;
      line-height: 37px;
    }

    &__subtitle{
      @include bp('del-breakpoint-l') {
        margin-bottom: 16px;
      }
    }

    &__subtitle,
    &__subtitle > p {
      display: inline-block;
      color: $del-color-white;
      @include del-font-medium;
      font-size: REM(12px);
      letter-spacing: 1.714px;
      line-height: normal;
      opacity: 0.7;
      @include bp('del-breakpoint-l') {
        font-size: REM(14px);
        opacity: 0.6;
      }
    }

    &__title,
    &__title > p {
      display: inline-block;
      color: $del-color-white;
      @include del-font-medium;
      font-size: REM(24px);
      letter-spacing: normal;
      line-height: REM(36px);

      @include bp('del-breakpoint-s') {
        font-size: REM(21px);
      }

      @include bp('del-breakpoint-m') {
        font-size: REM(24px);
      }

      @include bp('del-breakpoint-l') {
        font-size: REM(37px);
        line-height: REM(37px);
      }
    }

    &__previewtext {
      display: none;
      height: 1px;
      transition: height 0.3s;
      max-width: 340px;
      color: $del-color-white;
      @include del-font-light;
      font-size: REM(18px);
      letter-spacing: 0;
      line-height: REM(24px);
      text-align: center;
      overflow-y: hidden;

      @include bp('del-breakpoint-l') {
        display: block;
      }
    }

    &__fulltext {
      display: none;
      color: $del-color-white;
      @include del-font-light;
      font-size: REM(16px);
      line-height: REM(19px);
      letter-spacing: normal;
      margin-top: 12px;
      @include lineClamp(3);

      @include bp('del-breakpoint-s') {
        max-width: 490px;
        font-size: REM(12px);
        line-height: REM(14px);
      }

      @include bp('del-breakpoint-m') {
        font-size: REM(16px);
        line-height: REM(19px);
        margin-top: 13px;
        max-width: 710px;
      }

      @include bp('del-breakpoint-l') {
        font-size: REM(18px);
        letter-spacing: 0;
        line-height: REM(21px);
        @include lineClamp(10);
        max-width: 373px;
        margin-bottom: 37px;
      }
    }
  }
}
