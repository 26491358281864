.del-spinner {
  height: 32px;
  width: 32px;
  border: 3px solid;
  border-top-color: $del-color-bright-blue;
  border-right-color: $del-color-grey;
  border-bottom-color: $del-color-grey;
  border-left-color: $del-color-grey;
  border-radius: 50%;
  animation: $del-animation-spinner;
}
