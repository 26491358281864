.del-promosliderbadge {
  &__title {
    @include del-font-medium;
    font-size: REM(20px);
    line-height: normal;
    letter-spacing: 2px;
    color: $del-color-text;
    padding-block: 22px;
    text-align: center;

    @include bp('del-breakpoint-l') {
      font-size: REM(22px);
      padding-block: 38px;
    }
  }

  &__card {
    position: relative;
    display: flex;
    flex-direction: column;

    @include bp('del-breakpoint-s') {
      flex-direction: row;
    }
  }

  &__card__ribbon {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 139px;
    height: 139px;
    overflow: hidden;

    @include bp('del-breakpoint-s') {
      width: 100px;
      height: 100px;
    }

    @include bp('del-breakpoint-m') {
      width: 139px;
      height: 139px;
    }

    &::before,
    &::after {
      position: absolute;
      z-index: -1;
      content: '';
      display: block;
      border: 5px solid transparent;
    }

    &::before {
      top: -5px;
      left: 0;
      border-bottom-color: $del-color-text-60;
    }

    &::after {
      bottom: 0;
      right: -5px;
      border-left-color: $del-color-text-60;
    }

    span {
      position: absolute;
      left: -15px;
      top: 26px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 210px;
      padding: 15px 0;
      transform: rotate(45deg);
      background-color: $del-color-icon-blue;
      box-shadow: 0 5px 10px rgba($del-color-black, 0.1);
      @include del-font-medium;
      font-size: REM(14px);
      letter-spacing: 0.3px;
      line-height: 19px;
      text-shadow: 0 1px 1px rgba($del-color-black, 0.2);
      text-transform: uppercase;
      text-align: center;
      color: $del-color-white;

      @include bp('del-breakpoint-s') {
        left: -32px;
        top: 21px;
        width: 200px;
        padding: 8px 0;
        font-size: REM(12px);
      }

      @include bp('del-breakpoint-m') {
        left: -15px;
        top: 26px;
        width: 210px;
        padding: 15px 0;
        font-size: REM(14px);
      }

      @include bp('del-breakpoint-l') {
        font-size: REM(16px);
      }

      @include bp('del-breakpoint-l') {
        font-size: REM(18px);
      }
    }
  }

  &__card__picture,
  &__card-texts-wrapper {
    width: 100%;
    display: block;
    @include bp('del-breakpoint-s') {
      width: 50%;
    }
  }

  &__card__picture {
    aspect-ratio: 328 / 230;
    @include bp('del-breakpoint-s') {
      aspect-ratio: 335 / 380;
    }
    @include bp('del-breakpoint-m') {
      aspect-ratio: 402 / 456;
    }
    @include bp('del-breakpoint-xl') {
      aspect-ratio: 460 / 519;
    }
    img {
      max-width: 100%;
      display: block;
      object-fit: cover;
      aspect-ratio: 328 / 230;
      @include bp('del-breakpoint-s') {
        aspect-ratio: 335 / 380;
      }
      @include bp('del-breakpoint-m') {
        aspect-ratio: 402 / 456;
      }
      @include bp('del-breakpoint-xl') {
        aspect-ratio: 460 / 519;
      }
    }
  }

  &__card-texts-wrapper {
    display: flex;
    color: $del-color-white;
    background-color: $del-color-dark-blue;
    padding-top: 31px;
    padding-bottom: 38px;
    border: 1px solid $del-color-dark-blue;
    border-top-width: 0;

    &.--theme-white {
      color: $del-color-text;
      background-color: $del-color-white;
      border-color: $del-color-grey;
    }

    @include bp('del-breakpoint-s') {
      border-left-width: 0;
      border-top-width: 1px;
    }
  }

  &__card-texts {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 262px;
    margin-inline: auto;

    @include bp('del-breakpoint-l') {
      max-width: 356px;
    }
  }

  &__card__badge {
    position: absolute;
    top: 10px;
    left: 10px;
    @include bp('del-breakpoint-s') {
      position: relative;
      top: unset;
      left: unset;
      width: 60px;
      height: 60px;
      margin-bottom: 10px;
    }
    @include bp('del-breakpoint-m') {
      width: 80px;
      height: 80px;
    }
  }

  &__card-title p,
  &__card-content p {
    margin: 0;
  }

  &__card-title,
  &__card-title p {
    @include del-font-medium;
    font-size: REM(26px);
    line-height: 28px;
    margin-bottom: 9px;

    @include bp('del-breakpoint-xl') {
      font-size: REM(40px);
      line-height: 42px;
      margin-bottom: 16px;
    }
  }

  &__card-content,
  &__card-content p {
    @include del-font-medium;
    font-size: REM(16px);
    line-height: 19px;
    margin-bottom: 12px;

    @include bp('del-breakpoint-l') {
      max-width: 302px;
      margin-inline: auto;
    }

    @include bp('del-breakpoint-xl') {
      font-size: REM(18px);
      line-height: 21px;
      margin-bottom: 43px;
    }
  }

  .del-button {
    @include del-font-medium;
    font-size: REM(18px);
    line-height: 19px;
    height: auto;
  }
}
