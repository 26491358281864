.del-compare {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 15;
}

.del-compare-menu {
  background-color: $del-color-pale-blue;
  border-top: 1px solid $del-color-text-80;

  &__header {
    @include del-font-heavy;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    font-size: REM(14px);
    text-align: center;
    cursor: pointer;
    color: $del-color-text;

    @include bp('del-breakpoint-m') {
      font-size: REM(16px);
    }
  }

  &__header-icon {
    height: 20px;
    width: 20px;
    margin: 0 20px;
  }

  &__items-row {
    max-width: 1400px;
    display: flex;
    flex-direction: column-reverse;
    padding: 0 15px;
    margin: 0 auto;

    @include bp('del-breakpoint-m') {
      flex-direction: row;
      justify-content: space-between;
      padding: 0 15px 15px;
    }
  }

  &__container {
    width: calc(33% - 1px);
    height: 105px;

    @include bp('del-breakpoint-m') {
      width: calc(33% - 17px);
      flex-direction: row;
      padding: 5px;
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      background-color: var(--brand-03);
    }
  }

  &__container-empty {
    width: calc(33% - 1px);
    height: 105px;
    background-color: var(--brand-03);

    @include bp('del-breakpoint-m') {
      width: calc(33% - 17px);
      flex-direction: row;
      padding: 5px;
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      background-color: var(--brand-03);
    }
  }

  &__items {
    height: 172px;
    display: flex;
    justify-content: space-between;

    @include bp('del-breakpoint-m') {
      width: calc(75% - 10px);
      max-width: 1020px;
      align-items: center;
      height: 115px;
    }
  }

  &__item {
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: $del-color-white;

    @include bp('del-breakpoint-m') {
      flex-direction: row;
      padding: 5px;
      position: static;
    }
  }

  &__item-img {
    width: 70px;
    margin: auto;

    @include bp('del-breakpoint-m') {
      width: 90px;
      margin: 0 0 0 15px;
    }
  }

  &__item-info {
    display: none;

    @include bp('del-breakpoint-m') {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 5px;
      text-align: center;
      align-items: flex-start;
    }
  }

  &__item-info-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 5px 0;
    text-align: center;
    align-items: flex-start;

    @include bp('del-breakpoint-m') {
      display: none;
    }
  }

  &__item-name {
    @include del-font-medium;
    @include useEllipsisText();
    max-height: 38px;
    line-height: 19px;
    text-align: left;
    margin-bottom: 4px;
    font-size: REM(14px);

    @include bp('del-breakpoint-m') {
      @include useEllipsisText($lines: 2);
      max-height: 38px;
      font-size: REM(16px);
    }
  }

  &__item-code {
    color: $del-color-text-60;

    @include del-font-medium;
    font-size: REM(12px);

    @include bp('del-breakpoint-m') {
      font-size: REM(12px);
    }
  }

  &__item-remove {
    position: absolute;
    top: 3px;
    right: 3px;
    cursor: pointer;

    @include bp('del-breakpoint-m') {
      top: 5px;
      right: 5px;
    }
  }

  &__item-remove-icon {
    height: 15px;
    width: 15px;

    @include bp('del-breakpoint-m') {
      height: 18px;
      width: 18px;
    }
  }

  &__item-empty {
    height: 105px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;

    @include del-font-book;
    font-size: REM(50px);
    color: $del-color-light-grey;

    @include bp('del-breakpoint-m') {
      font-size: REM(89px);
    }
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    margin: 15px 0;

    @include bp('del-breakpoint-m') {
      width: calc(25% - 30px);
      flex-direction: column;
      justify-content: space-evenly;
      margin: 0;
    }

    &-clear {
      border: none;
      text-decoration: underline;
      &:hover {
        background-color: transparent;
      }
      &:active {
        background-color: transparent;
      }
    }
  }
}

.del-compare-table {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $del-color-white;
  padding: 0;

  &__container {
    height: 100%;
    overflow-y: auto;
  }

  &__header {
    top: 0;
    left: 0;
    width: 100%;
    padding: 15px 15px 20px 15px;
    background-color: $del-color-white;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
  }

  &__header-top {
    margin-top: 12px;
    @include bp('del-breakpoint-m') {
      margin: 50px 0 35px 0;
    }
  }

  &__header-text {
    color: $del-color-pure-black;
    @include del-font-medium;
    text-transform: uppercase;
    text-align: center;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 3px;
    font-size: REM(14px);

    @include bp('del-breakpoint-m') {
      font-size: REM(20px);
    }
  }

  &__header-close {
    position: relative;
    top: -22px;
    left: calc(100% - 18px);
    cursor: pointer;
    z-index: 30;
  }

  &__header-close-icon {
    height: 28px;
    width: 28px;
  }

  &__header-bottom {
    display: flex;
    margin-top: 25px;

    @include bp('del-breakpoint-m') {
      max-width: 1400px;
      margin: 0 auto;
    }
  }

  &__header-item {
    height: 100%;
    width: 33%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-right: 1px solid $del-color-light-grey;

    @include bp('del-breakpoint-m') {
      width: 25%;
    }

    &:first-of-type {
      @include bp('del-breakpoint-m') {
        margin-left: 12vw;
      }
    }

    &:nth-of-type(3) {
      border-right: none;
    }
  }

  &__header-item-remove {
    position: absolute;
    top: 7px;
    right: 7px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__header-item-remove-text {
    display: none;

    @include bp('del-breakpoint-m') {
      display: inline;

      @include del-font-medium;
      font-size: REM(14px);
    }
  }

  &__header-item-remove-icon {
    width: 15px;
    height: 15px;

    @include bp('del-breakpoint-m') {
      width: 25px;
      height: 25px;
    }
  }

  &__header-item-img {
    width: 75px;
    margin: 5px 0;

    @include bp('del-breakpoint-m') {
      width: 210px;
    }
  }

  &__header-item-info {
    padding: 0 15px;
    width: 100%;

    @include bp('del-breakpoint-m') {
      display: flex;
      padding: 15px 20px;
      width: 100%;
      flex-direction: column;
    }

    .dlg-omnibus-price {
      padding: 0;

      @include bp('del-breakpoint-s') {
        padding: 0 30px;
      }

      &__main-price {
        font-size: REM(16px);

        @include bp('del-breakpoint-s') {
          font-size: REM(19px);
        }
      }

      &__old-price {
        font-size: REM(12px);

        @include bp('del-breakpoint-s') {
          align-items: flex-end;
          font-size: REM(14px);
        }
      }
    }
  }

  &__header-item-info-left {
    @include bp('del-breakpoint-m') {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 20px;
      padding: 0 30px;
    }

    .del-product-info__energy-label {
      padding: 20px 0 0 0;

      .label img {
        width: 27px;
        height: 16px;

        @include bp('del-breakpoint-s') {
          width: 40px;
          height: 23px;
        }
      }

      .fiche {
        font-size: REM(11px);
        @include bp('del-breakpoint-m') {
          font-size: REM(14px);
        }
      }
    }
  }

  &__header-item-name {
    @include del-font-medium;
    @include useEllipsisText();
    font-size: REM(14px);
    max-height: 38px;
    width: 100%;
    line-height: 19px;
    text-align: left;
    margin-bottom: 4px;

    @include bp('del-breakpoint-m') {
      @include useEllipsisText($lines: 2);
      font-size: REM(20px);
      max-height: 46px;
      line-height: 23px;
    }
  }

  &__header-item-code {
    color: $del-color-text-60;
    text-align: left;

    @include del-font-medium;
    font-size: REM(12px);

    @include bp('del-breakpoint-m') {
      text-align: left;
      font-size: REM(14px);
      margin: 5px 0;
    }
  }

  &__header-item-reviews {
    display: none;

    @include bp('del-breakpoint-m') {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 15px 50px;
    }
  }

  &__header-item-reviews-number {
    padding: 0 5px;
    color: $del-color-text-60;

    @include del-font-book;
    font-size: REM(14px);
  }

  &__header-item-cta {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 17px;
    width: 100%;
    padding: 0;

    @include bp('del-breakpoint-m') {
      padding: 0 50px;
    }
  }

  &__header-item-cta-mobile {
    @include del-font-medium;
    font-size: REM(12px);
    border: none;
    color: var(--brand-03);
    display: flex;
    width: 100%;
    padding: 10px 2px;
    justify-content: center;
    background-color: var(--brand-01);
    text-align: center;
    border-radius: 3px;
    margin: 0 12px;
    @include bp('del-breakpoint-m') {
      display: none;
    }
  }

  &__header-item-cta-desktop {
    display: none;

    @include bp('del-breakpoint-m') {
      display: inline;
      width: 100%;
      background-color: var(--brand-01);
      border: none;
      color: white;
    }
  }

  &__sections {
    height: auto;
    overflow-y: auto;
    margin: 0 auto;
    padding: 20px 15px;

    @include bp('del-breakpoint-m') {
      max-width: 1400px;
    }
  }

  &__section {
    @include bp('del-breakpoint-m') {
      border-right: 1px solid $del-color-grey;
      border-left: 1px solid $del-color-grey;
    }

    &:first-of-type {
      .del-compare-table__section-title {
        margin-top: 0;
      }
    }
  }

  &__section-title {
    margin: 30px 0 15px;

    @include bp('del-breakpoint-m') {
      width: 100%;
      padding: 10px;
      margin: 30px 0 0 0;
      background-color: $del-color-light-grey;
      border-bottom: 1px solid $del-color-grey;
    }
  }

  &__section-title-btn {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-transform: uppercase;

    @include del-font-medium;
    font-size: REM(16px);

    @include bp('del-breakpoint-m') {
      justify-content: space-between;
    }
  }

  &__section-title-btn-icon {
    height: 16px;
    width: 16px;
    margin: 0 5px;
  }

  &__section-row {
    display: flex;
    flex-wrap: wrap;

    &:last-of-type {
      border-bottom: 1px solid $del-color-grey;
    }

    @include bp('del-breakpoint-m') {
      border-bottom: 1px solid $del-color-grey;
    }
  }

  &__section-row-title {
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid $del-color-grey;
    border-top: 1px solid $del-color-grey;
    background-color: $del-color-light-grey;

    @include del-font-medium;
    font-size: REM(16px);

    @include bp('del-breakpoint-m') {
      width: 25%;
      background-color: $del-color-white;
      border-bottom: none;
      border-top: none;
      border-right: 1px solid $del-color-grey;

      &--different-values {
        background-color: $del-color-lighter-blue;
      }
    }
  }

  &__section-row-cell {
    width: 33%;
    padding: 10px;
    border-right: 1px solid $del-color-grey;
    text-align: center;

    @include del-font-book;
    font-size: REM(13px);

    &--different-values {
      background-color: $del-color-lighter-blue;
    }

    &:nth-of-type(4) {
      border-right: none;
    }

    @include bp('del-breakpoint-m') {
      width: 25%;
      font-size: REM(16px);
    }
    .icon-ok-blue {
      width: 25px;
      height: 25px;
    }
  }
}
