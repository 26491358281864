.del-beveragesdisplayslider {
  width: 100%;
  background-color: $del-color-deeper-blue;
  padding-bottom: 40px;
  padding-top: 70px;

  @include bp('del-breakpoint-s') {
    padding-bottom: 0;
  }

  &__texts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 26px;
    padding-inline: 19px;

    @include bp('del-breakpoint-s') {
      padding-inline: unset;
      max-width: 545px;
      margin-inline: auto;
    }

    @include bp('del-breakpoint-xl') {
      max-width: 758px;
    }
  }

  &__title,
  &__title p {
    @include del-font-medium;
    color: $del-color-white;
    font-size: REM(26px);
    line-height: normal;
    @include bp('del-breakpoint-xl') {
      font-size: REM(37px);
    }
  }

  &__description,
  &__description p {
    @include del-font-book;
    color: $del-color-white;
    font-size: REM(18px);
    line-height: 21px;
  }

  &__display {
    display: flex;
    justify-content: center;
    width: 100%;

    @include bp('del-breakpoint-s') {
      height: 700px;
      background: url('#{$pathAssets}/images/bg/bg-beveragedisplay-delonghi.jpg')
        no-repeat center top;
      background-position-y: 80px;
      background-size: 1100px;
    }

    @include bp('del-breakpoint-m') {
      background-size: 1420px;
    }

    @include bp('del-breakpoint-l') {
      height: 1080px;
      background-size: initial;
      background-position-y: 18px;
    }
  }

  &__carousel {
    width: 100%;
    height: 464px;
    background-image: radial-gradient(#2a3143 0%, $del-color-deeper-blue 70%, $del-color-deeper-blue 200px);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: 0 40px 40px 40px $del-color-deeper-blue inset;
    $buttonSize: 40px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;

		&.swiper-container-initialized {
			opacity: 1;
		}

    @include bp('del-breakpoint-s') {
      max-width: 353px;
      height: 208px;
      margin-top: 168px;
    }

    @include bp('del-breakpoint-m') {
      max-width: 471px;
      height: 277px;
      margin-top: 186px;
    }

    @include bp('del-breakpoint-l') {
      max-width: 614px;
      height: 345px;
    }

    &-titlebox {
      display: none;
      position: absolute;
      left: 0;
      z-index: 14;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 55px;

      @include bp('del-breakpoint-s') {
        display: flex;
      }
    }

    .swiper-button-next-bg {
      display: none;
      height: 100%;
      width: 200px;
      border: none;
      background-image: linear-gradient(to right, #00000000, $del-color-deeper-blue);
      position: absolute;
      right: 0;
      z-index: 9;

      @include bp('del-breakpoint-s') {
        display: block;
      }
    }

    .swiper-button-prev-bg {
      display: none;
      height: 100%;
      width: 200px;
      border: none;
      background-image: linear-gradient(to right, $del-color-deeper-blue, #00000000);
      position: absolute;
      z-index: 9;

      @include bp('del-breakpoint-s') {
        display: block;
      }
    }

    .swiper-button-prev {
      width: $buttonSize;
      height: $buttonSize;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;

      @include bp('del-breakpoint-s') {
        width: 25px;
        height: 25px;

        &::after {
          content: '';
        }
      }

      @include bp('del-breakpoint-m') {
        width: 30px;
        height: 30px;
      }

      @include bp('del-breakpoint-l') {
        width: 45px;
        height: 45px;
      }
    }

    .swiper-button-next {
      width: $buttonSize;
      height: $buttonSize;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;

      @include bp('del-breakpoint-s') {
        width: 25px;
        height: 25px;

        &::after {
          content: '';
        }
      }

      @include bp('del-breakpoint-m') {
        width: 30px;
        height: 30px;
      }

      @include bp('del-breakpoint-l') {
        width: 45px;
        height: 45px;
      }
    }

    &-wrapper {
      width: 100%;
      height: 100%;
    }
  }

  &__sidebox {
    width: 90px;
    height: 100%;

    &--displayuser {
      @include bp('del-breakpoint-s') {
        background: url('#{$pathAssets}/images/bg/bg-beveragedisplay-user-delonghi.svg')
          no-repeat 85%;
        background-size: 50px 20px;
      }

      @include bp('del-breakpoint-m') {
        background-position: center;
        background-size: 60px 20px;
      }

      @include bp('del-breakpoint-l') {
        background-position: center;
        background-size: 72px 23px;
      }
    }
  }

  &__slidertitle {
    @include del-font-medium;
    font-size: toRem(14px);
    color: $del-color-white;

    @include bp('del-breakpoint-m') {
      font-size: toRem(16px);
    }

    @include bp('del-breakpoint-m') {
      font-size: toRem(20px);
    }
  }

  .del-beverage {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;

    @include bp('del-breakpoint-s') {
      max-width: 120px;
      background-size: 230px;
    }

    @include bp('del-breakpoint-m') {
      max-width: 140px;
      background-size: 315px;
    }

    @include bp('del-breakpoint-m') {
      max-width: 260px;
      background-size: 400px;
    }

    &__caption {
      margin-bottom: 75px;
      @include del-font-medium;
      font-size: REM(18px);
      color: $del-color-white;

      @include bp('del-breakpoint-s') {
        margin-bottom: 15px;
        font-size: REM(12px);
      }

      @include bp('del-breakpoint-m') {
        margin-bottom: 15px;
        font-size: REM(14px);
      }
    }
  }
}

// Tablet 768px - 1024px
@include bp-range('del-breakpoint-s', 'del-breakpoint-max-m') {
  .del-beveragesdisplayslider {
    height: 574px;
    overflow: hidden;
    position: relative;
    margin: 0;

    &__display {
      background-position-y: 65px;
      background-size: 1010px;
      position: absolute;
      top: 200px;
    }

    &__carousel {
      margin-top: 152px;
      width: 333px;
      height: 188px;
    }

    &__carousel-titlebox {
      height: 35px;
    }

    .swiper-button-next,
    .swiper-button-prev {
      width: 20px;
      height: 20px;
    }
  }
}

// Tablet 1024px - 1200px
@include bp-range('del-breakpoint-m', 'del-breakpoint-max-l') {
  .del-beveragesdisplayslider {
    height: 682px;
    overflow: hidden;
    position: relative;
    margin: 0;

    &__display {
      background-position-y: 40px;
      background-size: 1390px;
      top: 105px;
    }

    &__carousel {
      width: 444px;
      height: 248px;
      margin-top: 163px;
    }

    &__carousel-titlebox {
      height: 40px;
    }

    .swiper-button-next,
    .swiper-button-prev {
      width: 25px;
      height: 25px;
    }
  }

  .del-beveragesdisplayslider .del-beverage {
    max-width: 130px;
    background-size: 260px;
  }
}
