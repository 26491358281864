.del-textgroup {
  &__cta {
    @extend .del-button;
    &--primary,
    &--primary--light,
    &--primary--light-mobile,
    &--primary--light-desktop,
    &--secondary,
    &--secondary--light,
    &--secondary--light-mobile,
    &--secondary--light-desktop,
    &--countdown,
    &--countdown--light,
    &--countdown--light-mobile,
    &--countdown--light-desktop {
      @extend .del-button--big;
    }
    &--primary,
    &--countdown {
      @extend .del-button--primary-darkBlue;
    }
    &--primary--light,
    &--primary--light-mobile,
    &--countdown--light,
    &--countdown--light-mobile {
      @extend .del-button--primary-white;
    }
    &--secondary,
    &--secondary--light,
    &--secondary--light-mobile,
    &--secondary--light-desktop {
      @extend .del-button--secondary;
    }
    &--secondary--light,
    &--secondary--light-mobile {
      @extend .del-button--secondary-white;
    }

    &--medium,
    &--medium--light,
    &--medium--light-mobile,
    &--medium--light-desktop,
    &--small,
    &--small--light,
    &--small--light-mobile,
    &--small--light-desktop {
      @extend .del-button--arrow-big;
    }
    &--medium,
    &--medium--light,
    &--medium--light-mobile,
    &--medium--light-desktop {
      @extend .del-button--arrow-left;
      @extend .del-button--arrow-left-big;
    }
    &--medium--light,
    &--medium--light-mobile {
      @extend .del-button--arrow-left--light;
    }
    &--small,
    &--small--light,
    &--small--light-mobile,
    &--small--light-desktop {
      @extend .del-button--arrow-right;
      @extend .del-button--arrow-right-big;
    }
    &--small--light,
    &--small--light-mobile {
      @extend .del-button--arrow-right--light;
    }


    @include bp('del-breakpoint-l') {
      &--primary--light-mobile,
      &--countdown--light-mobile {
        @include del-button--primary-darkBlue;
      }
      &--primary--light-desktop,
      &--countdown--light-desktop {
        @include del-button--primary-white;
      }
      &--secondary--light-mobile {
        @include del-button--secondary;
      }
      &--secondary--light-desktop {
        @include del-button--secondary-white;
      }
      &--medium--light-mobile {
        @include del-button--arrow-left;
      }
      &--small--light-mobile {
        @include del-button--arrow-right;
      }
      &--medium--light-desktop {
        @include del-button--arrow-left--light;
      }
      &--small--light-desktop {
        @include del-button--arrow-right--light;
      }
    }

  }
}