.dln-delonghi {
  .dlg-omnibus-price {
    &__main-price {
      color: $del-color-text;
    }

    &__old-price {
      color: $del-color-text-60;
    }
  }
}
