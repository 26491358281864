[dir="rtl"] {
  .del-footer {
    .del-iconlinks__link {
      border: none;

      &:nth-of-type(odd) {
        border-left: 1px solid rgba($del-color-white,0.1);
      }

      @include bp('del-breakpoint-l') {
        border-left: 1px solid rgba($del-color-white,0.1);

        &:last-of-type {
          border: none;
        }
      }
    }
  }
}
