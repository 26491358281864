.del-text-h1 {
  @include del-font-medium;

  font-size: toRem(37px);
  line-height: 105%;
  letter-spacing: -0.02em;

  @include bp('del-breakpoint-s') {
    font-size: toRem(50px);
  }
}

.del-text-h2 {
  @include del-font-medium;

  font-size: toRem(28px);
  line-height: 105%;
  letter-spacing: -0.01em;

  @include bp('del-breakpoint-s') {
    font-size: toRem(37px);
  }
}

.del-text-h3 {
  @include del-font-medium;

  font-size: toRem(21px);
  line-height: 110%;

  @include bp('del-breakpoint-s') {
    font-size: toRem(28px);
  }
}

.del-text-h4 {
  @include del-font-medium;

  font-size: toRem(18px);
  line-height: 110%;

  @include bp('del-breakpoint-s') {
    font-size: toRem(21px);
  }
}

.del-text-h5 {
  @include del-font-medium;

  font-size: toRem(16px);
  line-height: 120%;

  @include bp('del-breakpoint-s') {
    font-size: toRem(18px);
  }
}

.del-text-h6 {
  @include del-font-medium;

  font-size: toRem(13px);
  line-height: 130%;
}

.del-text-p1 {
  @include del-font-book;

  font-size: toRem(18px);
  line-height: 140%;

  @include bp('del-breakpoint-s') {
    font-size: toRem(21px);
  }
}

.del-text-p2 {
  @include del-font-book;

  font-size: toRem(16px);
  line-height: 140%;

  @include bp('del-breakpoint-s') {
    font-size: toRem(18px);
  }
}

.del-text-p3 {
  @include del-font-book;

  font-size: toRem(15px);
  line-height: 140%;

  @include bp('del-breakpoint-s') {
    font-size: toRem(16px);
  }
}

.del-text-p4 {
  @include del-font-book;

  font-size: toRem(14px);
  line-height: 140%;
}

.del-text-p5 {
  @include del-font-book;

  font-size: toRem(13px);
  line-height: 140%;
}
