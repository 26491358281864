.del-header {
  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 60px;
    padding: 10px 0;

    @include bp('del-breakpoint-s') {
      padding: 10px;
    }

    @include bp('del-breakpoint-l') {
      height: 93px;
      padding-bottom: 20px;
    }

    &-container {
      display: flex;
      justify-content: center;
      z-index: 6;
      width: 100%;
      background-color: $del-color-white;
    }
  }

  &--transparent {
    @include bp('del-breakpoint-l') {
      background-color: transparent;
    }
  }

  &--inverted {
    @include bp('del-breakpoint-l') {
      color: $del-color-white;

      .del-header__abovelinks {
        color: rgba($del-color-white, 0.6);
      }

      .del-header__button-container {
        .del-btnIcon {
          .del-button {
            &__search {
              background-image: url('#{$pathAssets}/images/delonghiIcons/search-w.svg');
            }

            &__cart {
              background-image: url('#{$pathAssets}/images/delonghiIcons/cart-w.svg');
            }

            &__wishlist {
              background-image: url('#{$pathAssets}/images/delonghiIcons/wishlist-w.svg');
            }

            &__account {
              background-image: url('#{$pathAssets}/images/delonghiIcons/account-w.svg');
            }
          }
        }
      }
    }
  }

  &--bordered {
    border-bottom: 1px solid $del-color-grey;

    &.del-header--menuopen {
      border: 0;
    }
  }

  &--standard,
  &--menuopen {
    @include bp('del-breakpoint-l') {
      animation: del-header-animation-to-white 0.3s ease;
      background-color: $del-color-white;
      color: $del-color-text;

      .del-header__abovelinks {
        color: $del-color-text;
      }

      .del-header__button-container {
        .del-btnIcon {
          .del-button {
            &__search {
              background-image: url('#{$pathAssets}/images/delonghiIcons/search.svg');
            }

            &__cart {
              background-image: url('#{$pathAssets}/images/delonghiIcons/cart.svg');
            }

            &__wishlist {
              background-image: url('#{$pathAssets}/images/delonghiIcons/wishlist.svg');
            }

            &__account {
              background-image: url('#{$pathAssets}/images/delonghiIcons/account.svg');
            }
          }
        }
      }
    }
  }

  &--menuopen {
    @include bp('del-breakpoint-l') {
      top: 0;
    }
  }

  &__links-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-left: 27px;
  }

  &__toprow {
    display: none;
    justify-content: flex-end;
    margin-bottom: 10px;

    @include bp('del-breakpoint-l') {
      display: flex;
    }
  }

  &__bottomrow {
    display: flex;
    justify-content: flex-end;

    @include bp('del-breakpoint-l') {
      justify-content: space-between;
    }

    .del-header__navbar {
      display: none;
      align-items: stretch;

      @include bp('del-breakpoint-l') {
        display: flex;
      }

      li {
        margin: 0 13px 0 12px;
        @include del-font-medium;
        font-size: toRem(21px);
        line-height: 21px;
      }

      button,
      a {
        font: inherit;
        background: none;
        border: none;
        cursor: pointer;
      }

      a {
        color: var(--brand-01);
      }

      .del-option--selected {
        color: inherit;
        border-bottom: 2px solid $del-color-bright-blue;
      }
    }
  }

  &__abovelinks {
    display: flex;
    align-items: center;
    color: $del-color-text;

    li {
      margin-left: 20px;

      a {
        @include del-font-medium;
        font-size: toRem(14px);
        line-height: 21px;
        opacity: 0.8;
      }
    }
  }

  &__button-container {
    display: flex;

    .del-btnIcon {
      margin-left: 20px;

      &--wishlist {
        display: none;

        @include bp('del-breakpoint-l') {
          display: block;
        }
      }

      &--menu {
        @include bp('del-breakpoint-l') {
          display: none;
        }
      }

      .del-button {
        display: block;
        $size: 32px;
        width: $size;
        height: $size;

        border: none;
        background-color: transparent;

        &__search {
          background-image: url('#{$pathAssets}/images/delonghiIcons/search.svg');
        }

        &__cart {
          background-image: url('#{$pathAssets}/images/delonghiIcons/cart.svg');
        }

        &__wishlist {
          background-image: url('#{$pathAssets}/images/delonghiIcons/wishlist.svg');
        }

        &__account {
          background-image: url('#{$pathAssets}/images/delonghiIcons/account.svg');
        }
      }
    }
  }
}
