.dln-hotspot {
  &__wrapper {
    padding: 0;

    &-image {
      position: relative;
    }
  }

  &__mask {
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &--none {
      background-color: transparent;
    }

    &--white {
      background-color: $hs-white-mask;
    }

    &--black {
      background-color: $hs-black-mask;
    }

    &--grey {
      background-color: $hs-grey-mask;
    }

    &--blue {
      background-color: $hs-blue-mask;
    }
  }

  &__img {
    display: block;
    max-width: 100%;
  }

  &__single-point {
    background-color: rgba($hs-bg-spot, 0.4);
    border: 2px solid $hs-border-spot;
    transition: all 0.3s ease-in-out, left 0s, top 0s, visibility 0s;
    position: absolute;
    z-index: 2;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    // animation: hotspot-ripple .5s linear infinite;
    cursor: pointer;

    &.--hidden {
      opacity: 0;
    }

    &.--resizing {
      visibility: hidden;
    }

    @include bp($hs-bp-s) {
      width: 30px;
      height: 30px;
    }

    @include bp($hs-bp-l) {
      width: 40px;
      height: 40px;
    }

    &--mobile {
      @include bp($hs-bp-s) {
        display: none;
      }
    }

    &--desktop {
      display: none;
      @include bp($hs-bp-s) {
        display: block;
      }
    }

    &.--active,
    &:hover {
      border-color: $hs-border-hover-spot;
      // animation: unset;
    }
  }

  &__info {
    @include bp($hs-bp-s) {
      background-color: $hs-grey !important;
    }

    @include bp($hs-bp-l) {
      background-color: $hs-white !important;
    }

    &__title,
    &__content {
      display: block;
      @include hs-font-book;
      font-size: REM(18px);

      @include bp($hs-bp-s) {
        font-size: REM(12px);
      }

      @include bp($hs-bp-l) {
        font-size: REM(16px);
      }
    }

    &__title {
      @include hs-font-heavy;
      margin-bottom: 5px;
    }

    &-mobile {
      margin-block: 30px;
      @include bp($hs-bp-s) {
        display: none;
      }

      .swiper-wrapper {
        position: relative;
      }

      .swiper-slide {
        display: flex;
        flex-direction: column;
        gap: 10px;
        position: relative;
        max-width: 266px;
        padding-inline: 20px;
        border-right: 1px solid $hs-mobile-slide-separator;

        &:last-child {
          border-right: 0;
        }
      }
    }

    &-desktop {
      display: none;
      @include bp($hs-bp-s) {
        position: absolute;
        width: 300px;
        background-color: $hs-white;
        border-radius: 5px;
        z-index: 3;

        &.--active {
          display: block;
        }

        &.--top::before,
        &.--right::before,
        &.--bottom::before,
        &.--left::before {
          content: '';
          position: absolute;
          width: 9px;
          height: 9px;
          background-color: $hs-white;
          transform: rotate(45deg);

          @include bp($hs-bp-s) {
            background-color: $hs-grey;
          }
      
          @include bp($hs-bp-l) {
            background-color: $hs-white;
          }
        }

        &.--bottom::before {
          top: 10px;
        }

        &.--right::before {
          left: -4px;
        }

        &.--left::before {
          right: -4px;
        }

        &.--top::before {
          bottom: 10px;
        }

        &__inner {
          $marginTop: 20;
          $marginSide: 20;
          $marginBottom: 12;
          margin: #{$marginTop}px #{$marginSide}px #{$marginBottom}px;
          overflow-y: auto;
          max-height: #{130 - $marginTop - $marginBottom}px;

          @include bp($hs-bp-s) {
            margin: 31px 16px 23px 15px;
          }

          @include bp($hs-bp-l) {
            margin: 42px 22px 32px 20px;
          }
        }
      }
    }

    &-close {
      width: 16px;
      height: 16px;
      border: 0;
      background-color: transparent;
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;

      @include bp($hs-bp-l) {
        top: 12px;
        right: 12px;
      }
    }
  }
}

@keyframes hotspot-ripple {
  0% {
    box-shadow: 0 4px 10px rgba($hs-white, 0.2),
      0 0 0 0 rgba($hs-white, 0.2), 0 0 0 5px rgba($hs-white, 0.2),
      0 0 0 10px rgba($hs-white, 0.2);
  }
  100% {
    box-shadow: 0 4px 10px rgba($hs-white, 0.2),
      0 0 0 5px rgba($hs-white, 0.2),
      0 0 0 10px rgba($hs-white, 0.2),
      0 0 0 20px rgba($hs-white, 0);
  }
}
