@import 'layouts/primary';
@import 'layouts/countdown';
@import 'layouts/medium';
@import 'layouts/small';
@import 'layouts/generic';
@import 'layouts/divider';

@mixin textgroup-shared-texts {
  .del-textgroup__subtitle,
  .del-textgroup__subtitle p {
    @include del-font-medium;
    font-size: REM(14px);
    opacity: 0.6;
    letter-spacing: 2px;
    color: $del-color-text;
    margin-bottom: 15px;
    @include bp('del-breakpoint-l') {
      margin-bottom: 18px;
    }
  }
  .del-textgroup__subtitle p {
    margin: 0;
    opacity: unset;
  }
  .del-textgroup__title,
  .del-textgroup__title p {
    @include del-font-medium;
    font-size: REM(28px);
    line-height: 1.07;
    color: $del-color-text;
    margin-bottom: 15px;
    @include bp('del-breakpoint-l') {
      font-size: REM(37px);
      line-height: 1.05;
      margin-bottom: 17px;
    }
  }
  .del-textgroup__title p {
    margin: 0;
    opacity: unset;
  }
  .del-textgroup__separator {
    margin-bottom: 18px;
    @include bp-range('del-breakpoint-s', 'del-breakpoint-max-l') {
      margin-inline: auto;
    }
    @include bp('del-breakpoint-l') {
      margin-bottom: 26px;
    }
  }
  .del-textgroup__content {
    @include del-font-book;
    font-size: REM(16px);
    line-height: 1.17;
    color: $del-color-pure-black;
    margin-bottom: 24px;

    @include bp('del-breakpoint-l') {
      margin-bottom: 29px;
    }
  }
  .del-textgroup__cta--small {
    @include del-font-medium;
    font-size: REM(18px);
    color: $del-color-text;
    margin: 0;
  }
}
