.del-textgroup {
  &__countdown {
    display: flex;
    gap: 4px;
    margin-inline: auto;

    @include bp('del-breakpoint-l') {
      margin-inline: unset;
    }
  }

  &__countdown-card {
    position: relative;
    width: 78px;
    height: 84px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: $del-color-light-grey;

    @include bp('del-breakpoint-l') {
      width: 98px;
      height: 98px;
    }

    &::before {
      content: ' ';
      position: absolute;
      height: 2px;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
      background-color: $del-color-white;
    }
  }

  &__countdown-number {
    @include del-font-heavy;
    font-size: REM(50px);
    color: $del-color-text;
    transform: translateY(5px);
    @include bp('del-breakpoint-l') {
      font-size: REM(60px);
    }
  }

  &__countdown-label {
    display: block;
    @include del-font-medium;
    font-size: REM(12px);
    line-height: 1.17;
    color: $del-color-text;
    padding-top: 6px;
    text-align: center;
    @include bp('del-breakpoint-l') {
      font-size: REM(14px);
      line-height: 1;
    }
  }
}

@mixin del-countdown-colors {
  .del-textgroup {
    &__countdown-card {
      background-color: $del-color-white;

      &::before {
        background-color: $del-color-light-grey;
      }
    }

    &__countdown-number {
      color: $del-color-text;
    }

    &__countdown-label {
      color: $del-color-white;
    }
  }
}

.del-textgroup__texts--light,
.del-textgroup__texts--light-desktop {
  @include bp('del-breakpoint-l') {
    @include del-countdown-colors;
  }
}

.del-textgroup__texts--light,
.del-textgroup__texts--light-mobile {
  @include bp('del-breakpoint-max-l', 'max') {
    @include del-countdown-colors;
  }
}
