.del-moodboard.swiper-container {
  overflow-x: hidden;

  @include bp('del-breakpoint-l') {
    // tuning for hover animation on desktop, giving some room
    padding-block: 30px;
  }

  @include bp('del-breakpoint-gt-xxl') {
    // tuning for hover animation on desktop, giving some room
    // only for resolutions over 1920px width
    padding-inline: 50px;
    margin-inline: -50px;
  }

  .swiper-wrapper {
    position: relative;
    transform: translateX(calc(16px - 4px));
    @include bp('del-breakpoint-s') {
      transform: translateX(calc(40px - 4px));
    }
    @include bp('del-breakpoint-m') {
      transform: translateX(calc(70px - 4px));
    }
    @include bp('del-breakpoint-xl') {
      transform: translateX(0);
    }

    &.--count-2 {
      @media (min-width: 700px) { // tweekpoint
        justify-content: center;
        transform: translateX(0) !important;
      }
    }
  }

  .swiper-slide {
    overflow: hidden;
    max-width: 330px;
    padding-inline: 4px;

    @include bp('del-breakpoint-s') {
      max-width: 340px;
    }

    @include bp('del-breakpoint-m') {
      max-width: 438px;
    }

    @include bp('del-breakpoint-xl') {
      max-width: 960px;
      padding-inline: 0;
      &.--three-tiles {
        max-width: 640px;
      }
      &.--four-tiles {
        max-width: 480px;
      }
    }
  }

  &.swiper-initialized .swiper-button {
    visibility: visible;
  }

  .swiper-button {
    visibility: hidden;
    position: absolute;
    width: 40px;
    height: 40px;
    background-color: $del-color-white;
    border: 1px solid $del-color-text-60;
    border-radius: 50%;
    top: calc(50% - 27px);
    transform: translateY(-50%);
    
    &-prev {
      left: 6px;
      @include bp('del-breakpoint-s') {
        left: 35px;
      }
      @include bp('del-breakpoint-m') {
        left: 65px;
      }
    }

    &-next {
      right: 6px;
      @include bp('del-breakpoint-s') {
        right: 35px;
      }
      @include bp('del-breakpoint-m') {
        right: 65px;
      }
    }
  }

  .swiper-pagination {
    position: relative;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    padding-block: 21px;
    height: 54px;

    &-bullet {
      width: 8px;
      height: 8px;
      background-color: $del-color-text-60;

      &-active {
        width: 12px;
        height: 12px;
        background-color: $del-color-text;
      }
    }

    &-lock {
      visibility: hidden;
    }
  }
}