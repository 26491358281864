/*
*   In order to a partial reuse of Braun code: collection of rules, variables, functions and other related stuff borrowed from the Braun sibling project
*/
$braun-xs: 0 !default;
$braun-s: map-get($breakpoints, 'del-breakpoint-s') !default;
$braun-m: map-get($breakpoints, 'del-breakpoint-m') !default;
$braun-l: map-get($breakpoints, 'del-breakpoint-l') !default;
$braun-xl: map-get($breakpoints, 'del-breakpoint-xl') !default;

$ui-03: #F9F9F9;
$ui-08: #e6e6e6;
$brand-01: #000;

@mixin hover-supported {
  /*
  * https://developer.mozilla.org/en-US/docs/Web/CSS/@media/pointer
  * coarse: The primary input mechanism includes a pointing device of limited accuracy.
  * + IE11 support
  */
  @media not all and (pointer: coarse), not all and (hover: none), (-ms-high-contrast: none) {
    &:hover {
      @content;
    }
  }
}
