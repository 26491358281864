.dln-checkout__payment-methods-wrapper {
  .adyen-checkout__payment-method__radio {
    border: 1px solid $del-color-blue !important;
    box-shadow: none !important;
    width: 20px;
    height: 20px;

    &--selected {
      background-color: transparent;

      &::after {
        background-color: $del-color-blue;
        width: 70%;
        height: 70%;
      }
    }
  }

  .adyen-checkout__input {
    border-radius: 0;
  }

  .adyen-checkout__checkbox {
    .adyen-checkout__checkbox__input:hover:not(:focus)
      + .adyen-checkout__checkbox__label:after {
      border-color: $del-color-blue;
      box-shadow: none;
    }

    .adyen-checkout__checkbox__label {
      padding-top: 3px;
      &:before {
        border-color: transparent $del-color-blue $del-color-blue transparent !important;
        height: 8px;
        left: 3px;
        opacity: 0;
        top: 4px;
        width: 5px;
      }
      &:after {
        background-color: transparent !important;
        border: 1px solid $del-color-blue !important;
      }
    }
  }

  .adyen-checkout__button {
    border-radius: 0;
    background: $del-color-bright-blue;
    transition: all 0.3s ease-out;
    box-shadow: none;

    &:hover {
      background: $del-color-bright-blue;
      opacity: 0.8;
      box-shadow: none;
    }
  }
}
