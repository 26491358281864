.del-featureslist {
  &__texts {
    display: flex;
    flex-direction: column;
    gap: 15px;
    text-align: center;
    margin-inline: 16px;
    margin-bottom: 32px;
    
    @include bp('del-breakpoint-s') {
      margin-inline: auto;
      gap: 19px;
      max-width: 630px;
      margin-bottom: 39px;
    }

    @include bp('del-breakpoint-l') {
      gap: 22px;
      max-width: 1200px;
      margin-bottom: 84px;
    }
  }
  
  &__title {
    @include del-font-medium;
    font-size: REM(28px);
    color: $del-color-text;
    line-height: 1.07;
    letter-spacing: normal;

    @include bp('del-breakpoint-l') {
      font-size: REM(37px);
      line-height: 1.05;
    }
  }

  &__description {
    @include del-font-book;
    font-size: REM(18px);
    color: $del-color-pure-black;
    line-height: 1.17;
    letter-spacing: normal;

    @include bp('del-breakpoint-l') {
      font-size: REM(21px);
      line-height: 1.1;
    }
  }
}
