.del-multi-accordion {
  margin-inline: auto;
  max-width: calc(100% - 32px);
  list-style: none;

  @include bp('del-breakpoint-l') {
    max-width: 1156px;
  }

  &--gray {
    background-color: $del-color-white-smoke;
  }

  &__item {
    padding-top: 20px;
    padding-bottom: 30px;
    margin-inline: 16px;

    @include bp('del-breakpoint-s') {
      padding-bottom: 50px;
    }
    @include bp('del-breakpoint-l') {
      padding-bottom: 13px;
      margin-inline: 24px;
    }
  }

  &__separator {
      border: none;
      background-color: $del-color-white;
      margin: 0px;

    @include bp('del-breakpoint-xs') {
      padding-bottom: 1px;
      padding-top: 1px;
    }
    @include bp('del-breakpoint-m') {
      padding-bottom: 2px;
      padding-top: 2px;
    }
  }

  &__title {
    display: flex;
    flex-direction: column;
    gap: 20px;
    @include del-font-medium;
    font-size: REM(21px);
    line-height: REM(30px);
    color: $del-color-black;

    @include bp('del-breakpoint-l') {
      font-size: REM(26px);
      flex-direction: row;
      gap: 23px;
      align-items: center;
    }
  }

  &__subTitle,
  &__subTitle a {
    @include del-font-book;
    font-size: REM(16px);
    line-height: REM(19px);
    color: $del-color-black;
    max-width: calc(100% - 57px);
  }

  &__description,
  &__description p {
    @include del-font-book;
    font-size: REM(16px);
    line-height: REM(19px);
    color: $del-color-black;

    b,
    strong {
      @include del-font-heavy;
      display: block;
      margin-top: 30px;
    }
  }

  &__expander {
    position: relative;
    &.active {
      .del-multi-accordion__plusIcon::after {
        transform: translateX(-50%) rotate(90deg);
      }

      .del-multi-accordion__subTitle {
        display: none;
      }

      + .del-multi-accordion__content {
        height: 100%;
        overflow: visible;
        padding-top: 20px;
        padding-bottom: 129px;
        @include bp('del-breakpoint-s') {
          padding-bottom: 113px;
        }
        @include bp('del-breakpoint-m') {
          padding-bottom: 94px;
        }
        @include bp('del-breakpoint-l') {
          padding-top: 60px;
          padding-inline: 118px;
          padding-bottom: 125px;
        }
      }
    }

    padding-right: 18px;

    @include bp('del-breakpoint-l') {
      padding-right: 25px;
    }
  }

  &__plusIcon {
    height: 18px;
    width: 18px;
    position: absolute;
    top: 0;
    right: 0;

    @include bp('del-breakpoint-l') {
      right: 7px;
    }

    &::before,
    &::after {
      content: ' ';
      position: absolute;
      display: block;
      background: $del-color-pure-black;
      transition: transform 0.3s ease;
    }

    &::before {
      width: 18px;
      height: 2px;
      top: 50%;
      transform: translateY(-50%);
    }

    &::after {
      height: 18px;
      width: 2px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__content {
    height: 0;
    overflow: hidden;
  }
}
