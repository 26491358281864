@media only screen and (min-width: $braun-s) and (max-width: ($braun-m - 1)) {
  .del-gallery-spacecontainer {
    display: block;

    .bra-gallery {
      &__swiperWrapper {
        width: calc(100% - 40px);
        margin: 0 0 0 40px;
        .swiper-slide {
          max-width: 46.978021978% !important;
        }

        .swiper-footer {
          position: relative;

          display: flex;
          align-items: center;
          justify-content: space-around;

          width: 100% !important;
          margin: 0;
          padding-right: 40px;
          padding-top: 32px;
          position: relative;

          .swiper-button-close {
            display: none;
          }

          .swiper-button-prev {
            order: 1;
          }

          .swiper-scrollbar {
            display: block;
            order: 2;
          }

          .swiper-button-next {
            order: 3;
          }

          .swiper-button-next,
          .swiper-button-prev {
            display: none;
          }
        }
      }

      &.bra-gallery--grid-2 {
        .bra-gallery__swiperWrapper {
          width: calc(100% - 80px);
          padding: 0;
          margin: 0 auto;
          .swiper-slide {
            max-width: calc(50% - 2px) !important;
          }
          .swiper-footer {
            width: 100%;
            margin: 30px 0 0 0;
            display: none;
          }
        }
      }
    }

  }
}
