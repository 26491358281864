.del-sorting {
  width: 100%;
  padding: 30px 20px 140px;

  @include bp('del-breakpoint-s') {
    padding: 30px 0 50px;
  }

  &__title {
    @include del-font-medium;
    display: flex;
    justify-content: center;
    font-size: toRem(24px);
    text-align: center;

    @include bp('del-breakpoint-s') {
      font-size: toRem(32px);
    }

    @include bp('del-breakpoint-m') {
      font-size: toRem(34px);
    }

    @include bp('del-breakpoint-l') {
      font-size: toRem(37px);
    }

    &.del-warning {
      color: $del-color-red;
    }
  }

  & &__content {
    width: 270px;
    padding: 23px 0 80px;

    @include bp('del-breakpoint-s') {
      width: 660px;
    }

    @include bp('del-breakpoint-m') {
      width: 860px;
    }

    @include bp('del-breakpoint-l') {
      width: 1000px;
    }

    @include bp('del-breakpoint-xl') {
      width: 1132px;
    }

    .swiper {
      &-button {
        &-prev,
        &-next {
          display: flex;
          justify-content: center;
          align-items: center;
          top: 110px;
          width: 32px;
          height: 32px;
          border: 1px solid $del-color-text-60;
          border-radius: 50%;
          background-color: rgba($del-color-white, 0.8);

          @include bp('del-breakpoint-s') {
            top: 85px;
          }

          @include bp('del-breakpoint-m') {
            top: 105px;
            width: 40px;
            height: 40px;
            font-size: toRem(24px);
            font-weight: bold;
          }

          @include bp('del-breakpoint-l') {
            top: 120px;
          }

          @include bp('del-breakpoint-xl') {
            top: 140px;
          }

          &:after {
            font-size: toRem(18px);
            font-weight: bold;

            @include bp('del-breakpoint-m') {
              font-size: toRem(24px);
            }
          }
        }

        &-prev,
        [dir='rtl'] &-next {
          right: auto;
          left: 0;
        }

        &-next,
        [dir='rtl'] &-prev {
          left: auto;
          right: 0;
        }

        &-lock {
          display: none;
        }
      }

      &-pagination {
        &-bullets {
          display: flex;
          justify-content: center;
          align-items: center;

          > .swiper-pagination-bullet {
            background: $del-color-text-60;
            opacity: 1;
            margin: 0 10px;

            &-active {
              width: 12px;
              height: 12px;
              background: $del-color-black;
              margin: 0 8px;
            }
          }
        }

        &-lock {
          display: none;
        }
      }
    }
  }

  & &__item {
    display: flex;
    flex-direction: column;
    height: auto;

    &__top {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-grow: 0;
      width: 100%;

      &,
      &:visited,
      &:focus {
        text-decoration: none;
        color: inherit;
      }
    }

    &__image {
      width: 198px;

      @include bp('del-breakpoint-s') {
        width: 144px;
      }

      @include bp('del-breakpoint-m') {
        width: 194px;
      }

      @include bp('del-breakpoint-l') {
        width: 228px;
      }

      @include bp('del-breakpoint-xl') {
        width: 270px;
      }
    }

    &__textbox {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    &__caption,
    &__paragraph {
      padding: 5px 0;
      line-height: 1;
      text-align: center;
    }

    &__caption {
      @include del-font-medium;
      font-size: toRem(17px);
      color: $del-color-black;

      &.--comparative {
        @include del-font-demi;
        font-size: REM(17px);
        line-height: 19px;
        letter-spacing: 0;
        color: $del-color-text;
        display: flex;
        flex-direction: column;

        @include bp('del-breakpoint-s') {
          font-size: REM(16px);
          line-height: 20px;
        }

        @include bp('del-breakpoint-m') {
          font-size: REM(18px);
          line-height: 21px;
        }

        @include bp('del-breakpoint-l') {
          font-size: REM(26px);
          line-height: 30px;
        }

        span {
          @include del-font-medium;
          font-size: REM(16px);
          margin-top: 2px;
        }
      }

      @include bp('del-breakpoint-s') {
        font-size: toRem(19px);
      }

      @include bp('del-breakpoint-m') {
        font-size: toRem(22px);
      }

      @include bp('del-breakpoint-l') {
        font-size: toRem(26px);
      }
    }

    &__paragraph {
      @include del-font-light;
      font-size: toRem(15px);

      &.--comparative {
        @include del-font-book;
      }

      @include bp('del-breakpoint-s') {
        font-size: toRem(16px);
      }
    }

    &__top-paragraph {
      @include del-font-medium;
      font-size: REM(12px);
      letter-spacing: 1.71px;
      color: $del-color-text;
      opacity: 0.6;
      margin-top: 15px;
      margin-bottom: 5px;
      text-align: center;

      @include bp('del-breakpoint-l') {
        font-size: REM(14px);
        letter-spacing: 2px;
        margin-top: 20px;
        margin-bottom: 0;
      }
    }

    &__bottom {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      flex-grow: 2;

      .js-empty-item:after {
        content: '';
        width: 15px;
        margin: auto 0;
        height: 1px;
        color: $del-color-text-80;
        background-color: $del-color-text-80;
        border: none;

        @include bp('del-breakpoint-s') {
          width: 12px;
        }

        @include bp('del-breakpoint-m') {
          width: 16px;
        }
      }
    }

    &__features {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      padding: 0 0 16px;

      @include bp('del-breakpoint-l') {
        padding: 10px 0 20px 0;
      }
    }

    &__feature {
      @include del-font-book;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
      border-bottom: 1px solid $del-color-grey;
      padding: 20px 0;
      font-size: REM(15px);
      letter-spacing: 0;
      line-height: 20px;
      text-align: center;
      color: $del-color-text-80;

      @include bp('del-breakpoint-s') {
        font-size: REM(12px);
        line-height: 16px;
        padding: 15px 0;
      }

      @include bp('del-breakpoint-m') {
        font-size: REM(16px);
        line-height: 20px;
      }

      @include bp('del-breakpoint-l') {
        line-height: 20px;
      }

      &:last-child {
        border-bottom: none;
      }

      hr {
        width: 15px;
        margin: auto 0;
        height: 1px;
        color: $del-color-text-80;
        background-color: $del-color-text-80;
        border: none;

        @include bp('del-breakpoint-s') {
          width: 12px;
        }

        @include bp('del-breakpoint-m') {
          width: 16px;
        }
      }
    }

    &__button {
      @include del-font-demi;
      width: 100%;
      font-size: REM(16px);
      letter-spacing: 0;
      line-height: 24px;

      @include bp('del-breakpoint-s') {
        font-size: REM(14px);
        padding: 8px 10px;
      }

      @include bp('del-breakpoint-m') {
        font-size: REM(18px);
        padding: 10px 10px;
      }
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 34px;

    @include bp('del-breakpoint-s') {
      flex-direction: row;
      justify-content: center;
    }

    .del-button {
      width: 100%;
      margin: 10px 0;

      @include bp('del-breakpoint-s') {
        width: auto;
        margin: 0 20px;
      }
    }
  }

  .swiper-button-next.swiper-button-disabled,
  .swiper-button-prev.swiper-button-disabled {
    pointer-events: auto;
  }
}

// Dark overrides
.del-sorting.--dark {
  *:not(a) {
    color: var(--brand-03) !important;
  }

  .del-sorting__item__feature {
    border-bottom: 1px solid var(--ui-12) !important;
  }

  .swiper-button-prev,
  .swiper-button-next {
    border: 1px solid var(--brand-03) !important;
    background: transparent !important;
  }

  .swiper-pagination-bullet {
    background: $del-color-text-60 !important;
    &.swiper-pagination-bullet-active {
      background: var(--brand-03) !important;
    }
  }
}
