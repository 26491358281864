/*
*   In order to a partial reuse of Braun code: collection of rules, variables, functions and other related stuff borrowed from the Braun sibling project
*/

$braun-xs: 0 !default;
/*
$braun-s: 768px !default;
$braun-m: 1024px !default;
$braun-l: 1200px !default;
*/
$braun-s: map-get($breakpoints, 'del-breakpoint-s') !default;
$braun-m: map-get($breakpoints, 'del-breakpoint-m') !default;
$braun-l: map-get($breakpoints, 'del-breakpoint-l') !default;
$braun-xl: map-get($breakpoints, 'del-breakpoint-xl') !default;

$del-video-controls-basecolor: $del-color-white;
/*
$brand-01: #000;
$brand-02: #707070;
$brand-03: #FFF;

$ui-01: #EAEAEA;
*/

@keyframes tapAnimation__inner {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1);
  }
  28% {
    transform: scale(0.8);
  }
  59% {
    transform: scale(0.8);
  }
  63% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes tapAnimation__outer {
  0% {
    transform: scale(2.2);
    opacity: 0;
  }
  24% {
    transform: scale(1.6);
    opacity: 1;
  }
  26% {
    transform: scale(1.6);
    opacity: 1;
  }
  48% {
    transform: scale(2.2);
    opacity: 0;
  }
  100% {
    transform: scale(2.2);
    opacity: 0;
  }
}

.del-stories {
  &__container {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding: 0 30px;
  }
  &__wrapper {
    width: 100%;
    overflow: hidden;
    background: white;
    position: relative;
    max-width: 1440px;
    height: 720px;
    font-size: toRem(15px);
    line-height: normal;
    color: white;
    border-radius: 5px;

    padding-top: 150%;

    @media only screen and (max-width: 1500px) {
      width: 100%;
      height: 0;
      padding-top: 150%;
    }

    &.ready {
      .del-stories__screen--start {
        opacity: 1;
        z-index: 2;
        visibility: visible;
      }
      .del-stories__screen--end,
      .del-stories__screen--loading {
        opacity: 0;
        z-index: 0;
        visibility: hidden;
      }
    }
    &.running {
      .del-stories__screen--start,
      .del-stories__screen--end,
      .del-stories__screen--loading {
        opacity: 0;
        z-index: 0;
        visibility: hidden;
      }
      .del-stories__slider {
        opacity: 1;
        z-index: 1;
        visibility: visible;
      }
    }
    &.end {
      .del-stories__screen--end {
        opacity: 1;
        z-index: 2;
        visibility: visible;
      }
      .del-stories__screen--start,
      .del-stories__screen--loading {
        opacity: 0;
        z-index: 0;
        visibility: hidden;
      }
    }
    & .del-stories__slider {
      & .swiper-slide {
        & .del-stories__slide--content {
          & > strong,
          & > p {
            font-size: toRem(15px);
            line-height: 22px;
          }
        }
      }
    }
  }

  &__screen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    border-radius: 5px;
    overflow: hidden;

    transition: opacity 0.3s ease-in-out, height 0.3s ease-in-out;

    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;

    &--content {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      height: 100%;
      width: 100%;

      display: flex;
      align-items: center;
    }

    &--background {
      width: 100%;
      height: 100%;
      transition: height 0.3s ease-in-out;
      user-select: none;
      pointer-events: none;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(black, 0.25);
      }

      .del-image__image {
        max-width: 100%;
      }

      .bra-image {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        height: 100%;
        width: 100%;
        padding-bottom: 100%;
        background-color: $ui-03;
        border-radius: 5px;
        overflow: hidden;
        transform: translateZ(0);

        @media (-ms-high-contrast: none) {
          position: relative;
          height: auto;
        }

        &__image {
          position: absolute;
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          top: 0;
          left: 0;

          @media (-ms-high-contrast: none) {
            position: relative;
            min-width: 100%;
            min-height: 100%;
            max-height: none;
            max-height: initial;
            width: 100%;
            height: auto;

            align-self: center;
            flex-shrink: 0;
          }
        }
      }
    }

    .buttonTap {
      padding: 7px;
      margin-top: 70px;
      user-select: none;
      box-sizing: border-box;
      border-bottom: 1px solid;
      font-size: REM(18px);
      font-weight: 400;
      font-style: normal;
      line-height: 1;

      @include bp('del-breakpoint-l') {
        font-size: REM(21px);
      }

      &::after,
      &::before {
        display: block;
        position: absolute;
        top: -60px;
        left: calc(50% - 15px);
        content: '';
        width: 30px;
        height: 30px;
        box-shadow: 0 0 0 3px white;
        border-radius: 60px;
        transform-origin: center;
        // background: lightslategray;
        // transform: translateX(-50%) translateY(50%);
      }
      &::after {
        box-shadow: 0 0 0 0.7px rgba(white, 0.8);
        animation: tapAnimation__outer 2.5s infinite ease-in-out;
        // animation-direction: alternate;
      }

      &::before {
        animation: tapAnimation__inner 2.5s infinite ease-in-out;
        // animation-direction: alternate;
      }
    }

    &--loading {
      background: white;

      font-size: toRem(12px);
      color: black;

      opacity: 1;
      z-index: 50;
    }
    &--start {
      opacity: 0;
      z-index: 0;

      cursor: pointer;

      .buttonTap {
        position: absolute;
        bottom: 30px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    &--end {
      opacity: 0;
      z-index: 0;

      cursor: pointer;

      .tapToReplay {
        position: absolute;

        @include bp('del-breakpoint-max-s', 'max') {
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }

        @include bp('del-breakpoint-l') {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .del-subtitle {
      max-width: 90%;
      margin: 0 auto;
      padding-bottom: 50px;
      color: white;
      font-size: REM(24px);
      line-height: REM(24px);
      font-style: normal;
      max-width: 281px;

      @include bp('del-breakpoint-s') {
        max-width: 438px;
      }

      @include bp('del-breakpoint-m') {
        max-width: 471px;
      }

      @include bp('del-breakpoint-l') {
        font-size: REM(42px);
        line-height: REM(42px);
        max-width: 808px;
      }

      &.del-stories__font-family--serif {
        @include del-font-serif-medium;
      }
    }
  }

  &__slider {
    opacity: 0;
    z-index: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out, height 0.3s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    & .swiper-scrollbar {
      display: none !important;
    }
  }
}

@keyframes fillBar {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.swiper-container.stories-slider {
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
  height: 100%;

  .swiper-slide {
    width: 100%;
    display: flex;
    align-items: flex-end;
    border-radius: 5px;
    overflow: hidden;
    transition: height 0.3s ease-in-out;

    &.topAligned {
      align-items: flex-start;
      .del-stories__slide--content {
        bottom: 0;
        top: 0;
      }
    }

    .del-stories__slide--visual {
      width: 100%;
      height: 100%;

      video {
        width: 100%;
        min-height: 100%;
        object-fit: cover;

        left: 50%;
        position: relative;
        transform: translateX(-50%);
      }

      .mobileVersion {
        display: none;
      }
      .desktopVersion {
        display: block;
        height: 100%;
      }

      .del-image__image {
        max-width: 100%;
      }

      .del-image {
        &::after {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          content: '';
          background: rgba(black, 0.15);
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .del-stories__slide--content {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 10;
      margin: 90px auto;
      margin-left: 10%;
      max-width: calc(80% / 2);
      overflow: hidden;

      font-size: REM(16px);
      line-height: REM(18px);

      left: 20px;

      margin: 50px auto;
      max-width: calc(100% - 40px);

      opacity: 0.85;

      strong {
        font-size: REM(24px);
        line-height: REM(24px);
        font-weight: 400;
        font-style: normal;

        @include del-font-medium;
      }

      * {
        display: inline-block;
        opacity: 0;
        transform: translateX(-60px);
        transition: all 0.8s ease-in-out;
      }

      @include bp('del-breakpoint-l') {
        font-size: REM(18px);
        line-height: REM(21px);
        strong {
          font-size: REM(37px);
          line-height: REM(37px);
        }
      }
    }

    .del-image {
      transition: height 0.3s ease-in-out;
      img {
        transition: height 0.3s ease-in-out;
      }
    }

    &.swiper-slide.current {
      .del-image {
        img {
          transform: scale(1.1);
          transition: transform 9s linear, height 0.3s ease-in-out;
        }
      }

      .del-stories__slide--content {
        * {
          opacity: 1;
          transform: translateX(0);
        }
      }
    }
  }

  .swiper-pagination {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    display: table;
    width: calc(80% + 8px);
    border-collapse: separate;
    border-spacing: 4px 0;

    &-bullets {
      .swiper-pagination-bullet {
        display: table-cell;
        border-radius: 0;
        height: 3px;
        background: rgba(white, 0.5);
        opacity: 1;

        i {
          display: block;
          height: 3px;
          width: 0;
          background: white;

          transition: width 1s ease-in-out;
        }
      }
      .swiper-pagination-bullet.full {
        i {
          width: 100%;
          transition: width 1s ease-in-out;
        }
      }
      .swiper-pagination-bullet.current {
        i {
          transition: none;
          animation: fillBar 8s linear 1 normal forwards;
        }
      }
    }
  }

  .swiper-footer {
    position: absolute;

    top: 0;
    height: 100%;
    width: 100%;
    max-width: none;
    margin: 0 auto;

    transition: height 0.3s ease-in-out;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .swiper-button-next,
    .swiper-button-prev {
      position: relative;
      background: none;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;
      width: auto;
      height: auto;
      margin: 30px;

      &.swiper-button-disabled {
        opacity: 0;
      }

      &::after {
        color: $del-video-controls-basecolor;
        font-weight: 700;
      }
    }

    .swiper-button-prev {
      order: 1;
      margin: 30px auto 30px 30px;
    }
    .swiper-button-next {
      order: 3;
      margin: 30px 30px 30px auto;
    }

    .swiper-button-close {
      position: absolute;
      top: 0px;
      right: 0px;
      padding: 18px;
      z-index: 50;

      cursor: pointer;

      .del-btn-close__icon {
        @include del-button--plus--bw('white', '45');
      }
    }
  }
}
