.del-header {
  &__navbar--mobile {
    padding: 24px 16px;
    list-style: none;

    @include bp('del-breakpoint-s') {
      padding: 24px 20px;
    }

    @include bp('del-breakpoint-l') {
      display: none;
    }

    .del-icon {
      $size: 20px;
      width: $size;
      height: $size;
    }

    .del-header__navbar {
      li {
        button,
        a {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          height: 60px;
          padding: 0 8px 0 20px;
          border: none;
          background: none;
          border-bottom: 1px solid $del-color-light-grey;

          span {
            @include del-font-medium;
            font-size: REM(20px);
            line-height: 2.75rem;
          }
        }

        a {
          color: var(--brand-01);
        }

        &:last-child button,
        &:last-child a {
          border: none;
        }
      }
    }
  }

  & &__iconlinks {
    margin: 24px 0;

    &--mobile {
      $size: 28px;
      width: $size;
      height: $size;
      margin: 0;
    }

    li {
      width: 100%;
      height: 48px;
      padding: 0 16px;

      a {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 100%;
      }

      span {
        margin: 0 8px;
        @include del-font-medium;
        font-size: REM(16px);
        line-height: 1.31;
      }
    }
  }
}
