$middle-right-gap: 9%;
html[dir='rtl'] {
  .del-product-moodboard {
    &__products {
      .swiper-pagination {
        @include bp('del-breakpoint-m') {
          &-bullet {
            margin:0 0 0 30px!important; //override swiper default
            &:first-child {
              margin:0; //override swiper default
            }
            &:last-child {
              margin:0 0 0 30px!important; //override swiper default
            }
          }
        }
      }
    }
  }

  .del-product-moodboard {
    &__product-wrapper {
      &.--full {
        @include bp('del-breakpoint-m') {
          padding: 30px 17.10% 30px 8px;
        }
      }
    }
  }
}
