.del-campaign-steps {
  padding-inline: 16px;

  @include bp('del-breakpoint-s') {
    padding-inline: 40px;
  }

  @include bp('del-breakpoint-m') {
    padding-inline: 70px;
  }

  &__title {
    @include del-font-medium;
    font-size: REM(28px);
    line-height: 30px;
    color: $del-color-text;
    margin-bottom: 40px;

    @include bp('del-breakpoint-l') {
      font-size: REM(37px);
      line-height: 37px;
      margin-bottom: 60px;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 25px;

    @include bp('del-breakpoint-s') {
      gap: 60px;
    }

    @include bp('del-breakpoint-l') {
      flex-direction: row;
      gap: 140px;
    }
  }

  &__image {
    max-width: 449px;
    aspect-ratio: 1/1;
    @include bp('del-breakpoint-l') {
      order: 1;
    }

    img {
      max-width: 100%;
      object-fit: cover;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  &__item {
    display: flex;
    gap: 16px;

    @include bp('del-breakpoint-s') {
      gap: 26px;
    }

    &-number {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border: 2px solid $del-color-text-60;
      border-radius: 100%;
      color: $del-color-text;
      font-size: REM(21px);
      padding-top: 4px;
      @include bp('del-breakpoint-s') {
        width: 60px;
        height: 60px;
        font-size: REM(24px);
        padding-top: 5px;
      }
    }

    &-texts {
      display: flex;
      flex-direction: column;
      gap: 7px;
      width: calc(100% - 56px);
      @include bp('del-breakpoint-s') {
        width: calc(100% - 86px);
        gap: 9px;
        max-width: 480px;
      }
    }

    &-title {
      @include del-font-medium;
      font-size: REM(24px);
      line-height: REM(24px);
      color: $del-color-text;
    }

    &-description {
      @include del-font-book;
      font-size: REM(18px);
      line-height: REM(21px);
      color: $del-color-text;
    }
  }

  &__button {
    margin-top: 46px;
    @include bp('del-breakpoint-s') {
      margin-top: 70px;
    }
  }
}
