.del-header {
  .del-stripe {
    display: flex;
    justify-content: center;
    align-items: stretch;
    height: 100%;
    width: 100%;
    padding: 8px 0;

    &__container {
      display: flex;
      justify-content: center;
      width: 100%;
      background-color: $del-color-dark-blue;

      &.del-stripe--hidden {
        display: none;
      }
    }

    &__link {
      display: flex;
      justify-content: center;
      width: auto;
      padding: 0 24.5px;

      @include bp('del-breakpoint-s') {
        border-right: 1px solid rgba($del-color-white, 0.2);

        &:last-child {
          border-right: none;
        }
      }

      &,
      & a {
        @include del-font-medium;
        color: $del-color-white;
        font-size: toRem(14px);
        line-height: 18.2px;
        letter-spacing: 0;
      }

      & a {
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .swiper-wrapper {
      display: flex;
      align-items: center;
    }

    .swiper-slide {
      text-align: center;
    }

    .swiper-button {
      &-prev,
      &-next {
        display: flex;
        justify-content: center;
        align-items: center;
        $size: 16px;
        width: $size;
        height: $size;
        top: 8px;

        &.useless {
          display: none;
        }

        &:after {
          content: '';
        }

        @include bp('del-breakpoint-s') {
          display: none;
        }

        .del-icon {
          $size: 13px;
          width: $size;
          height: $size;
        }
      }

      &-prev {
        left: 0;
        top: 50%;
        margin-top: -8px;
      }

      &-next {
        right: 0;
        top: 50%;
        margin-top: -8px;
      }
    }
  }

  @include bp('del-breakpoint-s') {
    .del-stripe {
      width: auto;
    }

    &.isCLSession {
      margin-top: 33px;
  
      .del-stripe__container {
        top: 0;
        position: fixed;
        z-index: 10;
  
        &.del-stripe--hidden {
          display: flex;
        }
      }
    }
  }

  &__stripe-gap {
    width: 100%;
    height: 34px;
  }
}
