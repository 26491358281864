/* Colors */
$del-color-pure-black: #000;
$del-color-black: #131518;
$del-color-black-70op: rgba(#131518, 0.7);
$del-color-text: #1a1d1f;
$del-color-text-80: #484a4c;
$del-color-text-60: #757678;
$del-color-text-40: #979797;
$del-color-grey: #e5e5e5;
$del-color-light-grey: #efefef;
$del-color-white-smoke: #f7f7f7;
$del-color-white: #fff;
$del-color-deep-blue: #0b1423;
$del-color-deeper-blue: #00071c;
$del-color-blue: #052644;
$del-color-bright-blue: #266bbf;
$del-color-icon-dark-blue: #253c58;
$del-color-icon-blue: #38699e;
$del-color-icon-light-blue: #70a4ce;
$del-color-light-blue: #58b0e2;
$del-color-lighter-blue: #e9f0f8;
$del-color-dark-blue: #0c2340;
$del-color-pale-blue: #c9e3f9;
$del-color-red: #de1c2f;
$del-color-green: #0f8a0f;
$del-color-none: transparent;

$del-color-white-light-mask: rgba($del-color-white, 0.75);
$del-color-white-mask: rgba($del-color-white, 0.5);
$del-color-black-dark-mask: rgba($del-color-pure-black, 0.7);
$del-color-black-mask: rgba($del-color-pure-black, 0.5);
$del-color-black-light-mask: rgba($del-color-pure-black, 0.1);
$del-color-grey-mask: rgba($del-color-pure-black, 0.03);
$del-color-blue-mask: rgba($del-color-deep-blue, 0.5);
$del-color-deeper-blue-dark-mask: rgba($del-color-deeper-blue, 0.5);
$del-color-deeper-blue-mask: rgba($del-color-deeper-blue, 0.3);
$del-color-deeper-blue-darkest-mask: rgba($del-color-deeper-blue, 0.7);

$bg-color-list: (
  none: $del-color-none,
  white: $del-color-white,
  white-smoke: $del-color-white-smoke,
  light-grey: $del-color-light-grey,
  text: $del-color-text,
  blue-del: $del-color-blue,
  black: $del-color-black,
  full-black: $del-color-pure-black,
);
