@mixin textgroup-subtitle--primary {
  font-size: toRem(11px);
  letter-spacing: 2.24px;
  margin: 4px 0;
  width: 100%;

  @include bp('del-breakpoint-xl') {
    font-size: toRem(14px);
    letter-spacing: 2.88px;
  }
}
@mixin textgroup-title--primary {
  font-size: toRem(32px);
  letter-spacing: -0.656px;
  line-height: 30px;
  margin: 4px 0 7px;

  @include bp('del-breakpoint-xs') {
    font-size: toRem(44px);
    letter-spacing: -0.88px;
    line-height: 40px;
    margin: 5px 0 12px;
  }

  @include bp('del-breakpoint-l') {
    font-size: toRem(58px);
    letter-spacing: -1.168px;
    line-height: 54px;
    margin: 10px 0 17px;
  }

  @include bp('del-breakpoint-xl') {
    font-size: toRem(72px);
    letter-spacing: -1.44px;
    line-height: 66px;
    margin: 12px 0 23px;
  }
}
@mixin textgroup-content--primary {
  font-size: toRem(16px);
  line-height: 19px;
  margin: 5px 0 10px;

  @include bp('del-breakpoint-xs') {
    font-size: toRem(17px);
    line-height: 21px;
  }

  @include bp('del-breakpoint-xl') {
    font-size: toRem(23px);
    line-height: 28px;
    margin: 8px 0 18px;
  }
}

.textgroup-subtitle--primary {
  @include textgroup-subtitle--primary;
}
.textgroup-title--primary {
  @include textgroup-title--primary;
}
.textgroup-content--primary {
  @include textgroup-content--primary;
}

@mixin textgroup--primary {
  .del-textgroup {
    &__subtitle {
      @extend .textgroup-subtitle--primary;
      p {
        @extend .textgroup-subtitle--primary;
      }
    }

    &__title {
      @extend .textgroup-title--primary;
      p {
        @extend .textgroup-title--primary;
      }
    }

    &__content {
      @extend .textgroup-content--primary;
      p {
        @extend .textgroup-content--primary;
      }
    }
  }
}
