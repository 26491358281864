.del-contentcards {
  &__card {
    &-content {
      display: flex;
      width: 100%;
      height: 100%;
      padding: 16px;

      @include bp-range('del-breakpoint-s', 'del-breakpoint-max-m') {
        padding-inline: 40px;
      }

      @include bp-range('del-breakpoint-m', 'del-breakpoint-max-l') {
        padding-inline: 70px;
      }

      &.card {
        &--closed {
          @include bp('del-breakpoint-l') {
            flex-direction: column;
            justify-content: flex-end;
          }

          &:hover {
            background: $del-color-deeper-blue-dark-mask;

            .del-contentcards__icon-plus {
              @include bp('del-breakpoint-l') {
                &--inner {
                  transform: scale(1, 1);
                }
              }
            }

            .del-textgroup__previewtext {
              @include bp('del-breakpoint-l') {
                height: 90px;
                margin-top: 16px;
              }
            }
          }

          &.card-mask {
            @include bp('del-breakpoint-l') {
              &--half {
                background: linear-gradient(
                  to bottom,
                  $del-color-none 50%,
                  $del-color-black-dark-mask
                );

                &:hover {
                  background: $del-color-deeper-blue-dark-mask;
                }
              }

              &--cover {
                background: $del-color-deeper-blue-mask;

                &:hover {
                  background: $del-color-deeper-blue-dark-mask;
                }
              }

              &--cover-dark {
                background: $del-color-deeper-blue-dark-mask;

                &:hover {
                  background: $del-color-deeper-blue-darkest-mask;
                }
              }
            }

            @include bp('del-breakpoint-max-l', 'max') {
              &__mobile {
                &--cover {
                  background: $del-color-deeper-blue-mask;

                  &:hover {
                    background: $del-color-deeper-blue-mask;
                  }
                }

                &--cover-dark {
                  background: $del-color-deeper-blue-dark-mask;

                  &:hover {
                    background: $del-color-deeper-blue-dark-mask;
                  }
                }
              }
            }
          }

          .del-textgroup {
            &__title,
            &__subtitle {
              @include bp('del-breakpoint-l') {
                text-align: center;
              }
            }
          }
        }

        &--open {
          flex-direction: column;
          justify-content: space-evenly;

          @include bp-range('del-breakpoint-s', 'del-breakpoint-max-m') {
            padding-block: 21.3px;
          }
    
          @include bp-range('del-breakpoint-m', 'del-breakpoint-max-l') {
            padding-block: 21.3px;
          }

          .del-contentcards {
            &__spacer {
              display: flex;
              justify-content: flex-end;
              width: 100%;
              height: 24px;
            }

            &__text-wrapper {
              justify-content: flex-end;

              @include bp('del-breakpoint-l') {
                justify-content: center;
                width: 410px;
                max-width: 75%;
                padding-left: 64px;
              }
            }

            &__icon-plus {
              display: none;
            }

            &__link {
              display: inline-flex;
              opacity: 1;
            }
          }

          .del-textgroup {
            opacity: 0;
            transition: opacity 0.3s;
            position: relative;

            @include bp('del-breakpoint-l') {
              align-items: flex-start;
              margin-left: 64px;
            }

            &--opaque {
              opacity: 1;
            }

            &__fulltext {
              display: block;
              opacity: 1;
            }
          }
        }

        &--shrunk {
          animation: del-contentcards-fade-in 1s ease-in-out;

          @include bp-range('del-breakpoint-s', 'del-breakpoint-max-m') {
            padding-block: 16px;
          }
    
          @include bp-range('del-breakpoint-m', 'del-breakpoint-max-l') {
            padding-block: 21.5px;
          }

          &.--four-tiles {
            @include bp-range('del-breakpoint-s', 'del-breakpoint-max-m') {
              padding-block: 13.5px;
            }
      
            @include bp-range('del-breakpoint-m', 'del-breakpoint-max-l') {
              padding-block: 22px;
            }
          }

          @include bp('del-breakpoint-l') {
            padding: 16px 0;
          }

          .del-contentcards {
            &__text-wrapper {
              width: 100%;
              display: flex;
              justify-content: space-between;
            }

            &__icon-plus {
              @include bp('del-breakpoint-l') {
                display: none;
              }
            }
          }

          .del-textgroup {
            //opacity: 0;
            transition: opacity 1s;

            @include bp('del-breakpoint-l') {
              width: 100%;
            }

            &--opaque {
              //opacity: 1;
            }
          }

          @include bp('del-breakpoint-l') {
            justify-content: flex-start;

            .del-contentcards {
              &__icon-plus {
                display: none;
              }
            }

            .del-textgroup {
              height: 100%;

              &__subtitle {
                display: none;
              }

              &__title {
                transform: rotate(90deg) translate(0px, -205px);
                transform-origin: left;
                width: 400px;
                text-align: left;
              }
            }
          }
        }
      }
    }
  }
}

[dir='rtl'] {
  .del-contentcards {
    &__card {
      &-content {
        &.card {
          &--open {
            .del-textgroup {
              @include bp('del-breakpoint-l') {
                margin-right: 64px;
                margin-left: 16px;
              }
            }
          }
        }
      }
    }
  }
}
