// Add to cart popup
.dln-delonghi .dln-addItem {
  max-width: none;
}

.dln-tabs__list.swiper-wrapper .swiper-slide {
  width: auto;
}

// Order Detail Receipts
.dln-delonghi {
  .dln-order--detail .dln-order__content .dln-return-order-receipt {
    background-color: var(--ui-05);
    padding: 22px 20px;
    margin-top: 24px;
    border: none;
  }

  .dln-return-order__header {
    margin-bottom: 22px;
    padding: 0;
    border: none;

    .dln-return-order__section-title {
      font-family: var(--fontNameDelonghi);
      font-weight: 500;
      font-style: normal;
      font-size: toRem(21px);
    }
  }

  &__content {
    margin: 0 0 40px;

    .dln-return-order-receipt__text {
      font-size: toRem(16px);
    }
  }

  .del-product-moodboard .swiper-container-horizontal>.swiper-pagination-bullets {
    bottom: 0;
  }
}

// loqate dropdown menu
.dln-delonghi {
  .pca {
    position: relative;

    .pcaautocomplete {
      z-index: 1 !important;
      left: 0 !important;
      top: 0 !important;
    }
  }
}

@media only screen and (max-width: $braun-s - 1) {
  .dln-delonghi {
    .del-hero-banner .del-textgroup__title { 
      font-size: toRem(30px);
      line-height: 35px;
    }
  }
}
