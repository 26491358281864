/*
@media only screen and (min-width: $braun-m) and (max-width: $braun-l - 1) {
*/
@media only screen and (min-width: $braun-m) {
  .del-gallery-spacecontainer {
    .bra-gallery__container {
      width: 100%;

      .bra-gallery {
        margin: 0 70px;

        &__wrapper {
          display: flex;
          justify-content: space-between;
          position: relative;
          overflow: hidden;
          height: 49.61vw;

          width: 100%;
          margin: 0 auto;
          max-width: 1440px;

          &.ready .bra-gallery__col--left,
          &.ready .bra-gallery__col--right {
            opacity: 1;
            transition: opacity 0.4s ease-in-out;
          }
        }

        &__col--left,
        &__col--right {
          display: flex;
          flex-wrap: wrap;
          width: calc(50% - 2px);

          opacity: 0;
          transition: opacity 0.4s ease-in-out;

          .bra-image__wrapper {
            width: 100%;
            border-radius: 5px;
            position: relative;
            overflow: hidden;
            transform: translate3d(0, 0, 0);

            .bra-image {
              padding-bottom: 0;
              height: 100%;

              .bra-image__image {
                position: relative;

                min-width: 100%;
                min-height: 100%;

                @include hover-supported() {
                  position: absolute;
                  width: auto;
                  height: auto;
                  max-height: 110%;
                  left: 50%;
                  top: 50%;
                  transform: translateX(-50%) translateY(-50%) scale3d(1, 1, 1);
                }
              }
            }

            &.cloned {
              border-radius: 5px;
              overflow: hidden;
              z-index: -10 !important;

              .bra-image__image {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%)
                  scale3d(1.05, 1.05, 1.05);
                transition: transform 0 ease-in-out;
              }
            }

            &.cloned.active,
            &.cloned.animating {
              z-index: 60 !important;

              .bra-image__image {
                transform: translateX(-50%) translateY(-50%)
                  scale3d(1.05, 1.05, 1.05);
                transition: transform 0.3s ease-in-out;
              }

              .bra-btnIcon {
                opacity: 1;
              }
            }

            &.closing {
              .bra-image__image {
                transform: translateX(-50%) translateY(-50%) scale3d(1, 1, 1) !important;
                transition: transform 0.3s ease-in-out;
              }
            }

            .bra-image {
              padding-bottom: 0;
              height: 100%;

              .bra-image__image {
                position: relative;

                min-width: 100%;
                min-height: 100%;

                @include hover-supported() {
                  position: absolute;
                  width: auto;
                  height: auto;
                  max-height: 110%;
                  left: 50%;
                  top: 50%;
                  transform: translateX(-50%) translateY(-50%) scale3d(1, 1, 1);
                }
              }
            }

            .bra-image__image {
              transition: all 0.3s ease-in-out;
            }

            .bra-image__overlay {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: rgba(black, 0.6);
              opacity: 0;
              z-index: 10;
              border-radius: 5px;
              overflow: hidden;
            }

            .bra-btnIcon {
              position: absolute;
              top: 15px;
              right: 24px;
              opacity: 0;
              z-index: 50;
              .bra-icn {
                color: #fff;
              }
            }
          }
        }

        &__col--left {
          .bra-image__wrapper {
            &:nth-child(1),
            &:nth-child(2) {
              width: calc(50% - 2px);
              aspect-ratio: 218/127.5;
              margin-bottom: 4px;
            }

            &:nth-child(1) {
              margin-right: 4px;
            }
          }
        }

        &__col--right {
          .bra-image__wrapper {
            &:nth-child(2),
            &:nth-child(3) {
              width: calc(50% - 2px);
              aspect-ratio: 218/127.5;
              margin-top: 4px;
            }

            &:nth-child(2) {
              margin-right: 4px;
            }
          }
        }

        .bra-icn.bra-icn__plus {
          @include del-button--plus-light;
        }

        &__swiperWrapper {
          position: absolute !important;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          // z-index: -100;
          visibility: hidden;
          background: white;
          border-radius: 5px;

          .swiper-slide {
            border-radius: 5px;
            overflow: hidden;

            .bra-image {
              border-radius: 5px;
              overflow: hidden;
              transform: translate3d(0, 0, 0);
              padding-bottom: 0;
              height: 100%;

              .bra-image__image {
                border-radius: 5px;
                overflow: hidden;
              }
            }

            .bra-image__image {
              transform: scale3d(1.05, 1.05, 1.05);
            }
          }

          &.visible {
            z-index: 100;
            visibility: visible;
          }

          .swiper-footer {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100% !important;
            margin: 0 !important;
            align-items: baseline;
            max-width: none !important;

            .swiper-scrollbar {
              display: none;
            }

            .swiper-button-next,
            .swiper-button-prev {
              position: absolute !important;
              top: 50% !important;
              transform: translateY(-50%) !important;
              border: none;
              border-radius: 50%;
              background-color: transparent;
              font-size: REM(24px);
              color: $del-color-white;
            }

            .swiper-button-prev.swiper-button-disabled,
            .swiper-button-next.swiper-button-disabled {
              opacity: 0.2;
              pointer-events: none;
            }

            .swiper-button-prev::after,
            .swiper-button-next::after {
              display: inline-block;
              font-family: swiper-icons, sans-serif;
              font-size: var(--swiper-navigation-size);
              font-variant: initial;
              text-transform: none !important;
              letter-spacing: 0;
            }

            .swiper-button-prev:after,
            .swiper-container-rtl .swiper-button-next:after {
              content: 'prev';
            }

            .swiper-button-next:after,
            .swiper-container-rtl .swiper-button-prev:after {
              content: 'next';
            }

            .swiper-button-next {
              right: 27px !important;
              left: auto !important;
            }

            .swiper-button-prev {
              right: auto !important;
              left: 27px !important;
            }

            .swiper-button-close {
              position: absolute;
              top: 0;
              right: 0;

              button {
                position: absolute;
                top: 14px;
                right: 24px;
                z-index: 50;
                transform: rotate(45deg);
              }

              .bra-icn {
                color: #fff;
              }
            }
          }
        }

        .bra-gallery--grid-4 {
          .bra-gallery__col--right {
            .bra-image__wrapper {
              margin: 0;
            }
          }
        }

        .bra-gallery--grid-3 {
          .bra-gallery__col--left {
            .bra-image__wrapper {
              &:nth-child(1),
              &:nth-child(2) {
                width: 100%;
                margin: 0;
              }

              &:nth-child(1) {
                margin-bottom: 4px;
              }
            }
          }

          .bra-gallery__col--right {
            .bra-image__wrapper {
              &:nth-child(2) {
                margin: 0;
              }
            }
          }
        }

        .bra-gallery--grid-2 {
          .bra-image__wrapper {
            min-height: initial;
          }

          .bra-gallery__col--left {
            .bra-image__wrapper {
              &:nth-child(1),
              &:nth-child(2) {
                width: 100%;
                margin: 0;
              }
            }
          }

          .bra-gallery__col--right {
            .bra-image__wrapper {
              &:nth-child(2) {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}
