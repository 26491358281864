@mixin del-hero-banner-aspect-ratio() {
  aspect-ratio: 360 / 320;
  &.--auto {
    aspect-ratio: unset;
  }
  @include bp('del-breakpoint-s') {
    aspect-ratio: 16 / 9;
    &.--small {
      aspect-ratio: 768 / 184;
    }
    &.--mid {
      aspect-ratio: 768 / 256;
    }
    &.--big {
      aspect-ratio: 768 / 320;
    }
  }
  @include bp('del-breakpoint-m') {
    &.--small {
      aspect-ratio: 1024 / 245;
    }
    &.--mid {
      aspect-ratio: 1024 / 342;
    }
    &.--big {
      aspect-ratio: 1024 / 427;
    }
  }
  @include bp('del-breakpoint-l') {
    &.--small {
      aspect-ratio: 1920 / 460;
    }
    &.--mid {
      aspect-ratio: 1920 / 640;
    }
    &.--big {
      aspect-ratio: 1920 / 800;
    }
  }
}

@mixin del-hero-banner-max-heights() {
  @include bp('del-breakpoint-s') {
    &.--small {
      max-height: 184px;
    }
    &.--mid {
      max-height: 256px;
    }
    &.--big {
      max-height: 320px;
    }
  }
  @include bp('del-breakpoint-m') {
    &.--small {
      max-height: 245px;
    }
    &.--mid {
      max-height: 342px;
    }
    &.--big {
      max-height: 427px;
    }
  }
  @include bp('del-breakpoint-l') {
    &.--small {
      max-height: 460px;
    }
    &.--mid {
      max-height: 640px;
    }
    &.--big {
      max-height: 800px;
    }
  }
}

.del-hero-banner {
  &__media {
    @include del-hero-banner-aspect-ratio;

    img,
    video {
      display: block;
      max-width: 100%;
      height: auto;
      object-fit: cover;
      @include del-hero-banner-aspect-ratio;
      @include del-hero-banner-max-heights;
    }
  }
}
