@mixin textgroup-subtitle--small {
  font-size: toRem(12px);
  letter-spacing: 2.4px;
  line-height: 10px;
  margin: 3px 0;
}
@mixin textgroup-title--small {
  @include del-font-medium;
  font-size: toRem(25px);
  letter-spacing: -0.24px;
  line-height: 26px;

  @include bp('del-breakpoint-l') {
    font-size: toRem(34px);
    letter-spacing: -0.34px;
    line-height: 37px;
    margin: 7px 0;
  }

  @include bp('del-breakpoint-xl') {
    font-size: toRem(37px);
    letter-spacing: -0.37px;
    line-height: 40.7px;
  }
}
@mixin textgroup-content--small {
  font-size: toRem(18px);
  line-height: 19px;
  margin: 7px 0 12px;
}

.textgroup-subtitle--small {
  @include textgroup-subtitle--small;
}

.textgroup-title--small {
  @include textgroup-title--small;
}

@mixin textgroup--small {
  .del-textgroup {
    &__subtitle {
      @include textgroup-subtitle--small;
      font-size: 14px;
      p {
        @include textgroup-subtitle--small;
      }
    }

    &__title {
      @include textgroup-title--small;
      font-size: 28px;

      @include bp('del-breakpoint-l') {
        font-size: 37px;
      }
      p {
        @include textgroup-title--small;
      }
    }

    &__content {
      @include textgroup-content--small;
      p {
        @include textgroup-content--small;
      }
    }
  }
}
