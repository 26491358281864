.del-mediagallery {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $del-color-white;

    &.light-theme {

        .del-mediagallery__heading__title,
        .del-mediagallery__heading__subtitle {
            color: $del-color-pure-black;
        }
    }

    &__heading {
        display: flex;
        flex-direction: column;
        padding-top: 26px;
        gap: 13px;
        text-align: center;

        @include bp('del-breakpoint-s') {
            padding-top: 24px;
            margin-bottom: 14px;
            gap: 11px;
        }

        @include bp('del-breakpoint-m') {
            padding-top: 25px;
            margin-bottom: 8px;
            gap: 11px;
        }

        @include bp('del-breakpoint-l') {
            padding-top: 40px;
            margin-bottom: 21px;
            gap: 25px;
        }

        &__title {
            font-size: 26px;
            color: $del-color-white;
            font-weight: 500;
            text-align: center;

            @include bp('del-breakpoint-l') {
                font-size: 37px;
            }
        }

        &__subtitle {
            font-size: 18px;
            color: $del-color-white;

            @include bp('del-breakpoint-l') {
                font-size: 21px;
            }
        }
    }

    &__gallery {
        display: grid;
        grid-template-columns: auto;
        gap: 16px;
        margin-top: 16px;

        &__tile {
            display: flex;
            align-items: center;
            overflow: hidden;

            & img {
                width: 100%;
            }
        }

        &.one-tile {
            grid-auto-columns: 336px;
            grid-template-rows: 338px;

            @include bp('del-breakpoint-s') {
                grid-auto-columns: 688px;
                grid-template-rows: 342px;
            }

            @include bp('del-breakpoint-m') {
                grid-auto-columns: 884px;
                grid-template-rows: 508px;
            }

            @include bp('del-breakpoint-xl') {
                grid-auto-columns: 1316px;
                grid-template-rows: 760px;
            }
        }

        &.two-tiles {
            grid-auto-columns: 336px;
            grid-template-rows: repeat(2, 338px);

            @include bp('del-breakpoint-s') {
                grid-template-columns: repeat(2, 336px);
                grid-template-rows: 341px;
            }

            @include bp('del-breakpoint-m') {
                grid-template-columns: repeat(2, 436px);
                grid-template-rows: 508px;
            }

            @include bp('del-breakpoint-xl') {
                grid-template-columns: repeat(2, 650px);
                grid-template-rows: 760px;
            }
        }

        &.three-tiles {
            grid-template-rows: repeat(2, 164px) 338px;

            @include bp('del-breakpoint-s') {
                grid-template-columns: repeat(2, 336px);
                grid-template-rows: repeat(2, 164px);

                div.del-mediagallery__gallery__tile:nth-child(1) {
                    grid-area: 1 / 1 / 2 / 2;
                    align-self: baseline;
                }

                div.del-mediagallery__gallery__tile:nth-child(2) {
                    grid-area: 2 / 1 / 3 / 2;
                }

                div.del-mediagallery__gallery__tile:nth-child(3) {
                    grid-area: 1 / 2 / 3 / 3;
                }
            }

            @include bp('del-breakpoint-m') {
                grid-template-columns: repeat(2, 434px);
                grid-template-rows: repeat(2, 246px);
            }

            @include bp('del-breakpoint-xl') {
                grid-template-columns: repeat(2, 650px);
                grid-template-rows: repeat(2, 372px);
            }
        }
    }
}