.del-keyfeatures {
  &__text {
    margin-bottom: 39px;
    padding-inline: 30px;
    display: none;
    
    @include bp('del-breakpoint-s') {
      margin-bottom: 62px;
      padding-inline: 68px;
    }

    @include bp('del-breakpoint-m') {
      margin-bottom: 64px;
      padding-inline: 70px;
      display: block;
    }

    @include bp('del-breakpoint-l') {
      padding-inline: 276px;
    }
  }


  &__title {
    @include del-font-medium();
    font-size: REM(26px);
    line-height: REM(28px);
    text-align: center;
    color: var(--brand-06);

    @include bp('del-breakpoint-l') {
      font-size: REM(37px);
    }
  }

  &__subtitle {
    @include del-font-book();
    font-size: REM(18px);
    line-height: REM(21px);
    text-align: center;
    color: var(--brand-06);
    margin-top: 13px;
    padding-inline: 8px;

    @include bp('del-breakpoint-s') {
      line-height: REM(20px);
      margin-top: 12px;
      padding-inline: 32px;
    }

    @include bp('del-breakpoint-m') {
      padding-inline: 95px;
    }

    @include bp('del-breakpoint-l') {
      font-size: REM(21px);
      line-height: REM(23px);
      margin-top: 22px;
      padding-inline: 0;
    }
  }
}