.del-plp-promo-banner {
  &-container-smartslot {
    display: none;

    @include bp('del-breakpoint-m') {
      display: flex;
      width: 50%;
      border: 1px dotted $del-color-none;

      &:hover {
        border-color: $del-color-grey;
      }
    }
  }

  &-doublebox {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 15px;
    background-color: $del-color-white;

    @include bp('del-breakpoint-s') {
      width: 50%;
    }

    .del-plp-promo-image-box {
      $promobox-height-ratio: 0.23571428571;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;       
      overflow: hidden;

      @include bp('del-breakpoint-s') {
        max-height: calc(100vw * #{$promobox-height-ratio});
      }

      @include bp('del-breakpoint-xl') {
        max-height: calc(1400px * #{$promobox-height-ratio});
      }

      img.del-plp-promo-image {
        width: 100%;
      }
    }

    .del-plp-promo-link {
      @include del-button;
      @include del-button--big;
      @include del-button--arrow-right;
      @include del-button--arrow-right-big;
      padding-top: 20px;
      font-size: toRem(19px);
      letter-spacing: normal;
    }
  }

  &-smartwrapper,
  &-smartwrapper &-doublebox {
    width: 100%;
  }
}
