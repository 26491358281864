.del-contentcards {
	position: relative;
	width: 100%;
	min-height: 640px;
	height: auto;
	background-color: rgb(0,6,27);

	@include bp('del-breakpoint-s') {
		min-height: 410px;
	}

	@include bp('del-breakpoint-m') {
		min-height: 548px;
	}

	@include bp('del-breakpoint-l') {
		height: 1024px;
	}

	&__cards_wrapper {
		position: absolute;
		left: 0;
		z-index: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: stretch;
		width: 100%;
		height: 100%;
		list-style-type: none;

		@include bp('del-breakpoint-l') {
			flex-direction: row;
		}
	}

	&__text-wrapper {
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		@include bp('del-breakpoint-l') {
			flex-direction: column;
			justify-content: center;
			height: auto;
			margin-bottom: 54px;
		}
	}
	&__card-content {
		height: 100%;
	}
}
