.del-promoslider {

  .swiper-wrapper {
    position: relative;
    transform: translateX(calc((100% - 338px) / 2));

    @include bp('del-breakpoint-s') {
      transform: translateX(calc((100% - 700px) / 2));
    }

    @include bp('del-breakpoint-m') {
      transform: translateX(calc((100% - 838px) / 2));
    }

    @include bp('del-breakpoint-xl') {
      transform: translateX(calc((100% - 1062px) / 2));
    }
  }

  .swiper-slide {
    position: relative;
    max-width: 338px;
    padding-inline: 5px;

    @include bp('del-breakpoint-s') {
      max-width: 700px;
      padding-inline: 15px;
    }

    @include bp('del-breakpoint-m') {
      max-width: 838px;
      padding-inline: 17px;
    }

    @include bp('del-breakpoint-xl') {
      max-width: 1062px;
      padding-inline: 71px;
    }
  }

  .swiper-container-initialized .swiper-button {
    visibility: visible;
  }

  .swiper-button {
    visibility: hidden;
    position: absolute;
    width: 40px;
    height: 40px;
    background-color: $del-color-white;
    border: 1px solid $del-color-text-60;
    border-radius: 50%;
    top: calc(50% - 27px);
    transform: translateY(-50%);

    &-prev {
      left: calc((100% - 338px) / 2);
      @include bp('del-breakpoint-s') {
        left: calc((100% - 690px) / 2);
      }
      @include bp('del-breakpoint-m') {
        left: calc((100% - 828px) / 2);
      }
      @include bp('del-breakpoint-xl') {
        left: calc((100% - 1052px) / 2);
      }
    }

    &-next {
      right: calc((100% - 338px) / 2);
      @include bp('del-breakpoint-s') {
        right: calc((100% - 690px) / 2);
      }
      @include bp('del-breakpoint-m') {
        right: calc((100% - 828px) / 2);
      }
      @include bp('del-breakpoint-xl') {
        right: calc((100% - 1052px) / 2);
      }
    }
  }

  .swiper-pagination {
    position: relative;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    padding-top: 23px;
    padding-bottom: 30px;
    bottom: unset !important;
    min-height: 65px;

    &-bullet {
      width: 8px;
      height: 8px;
      background-color: $del-color-text-60;

      &-active {
        width: 12px;
        height: 12px;
        background-color: $del-color-text;
      }
    }

    &-lock {
      visibility: hidden;
    }
  }
}
