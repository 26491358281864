.del-product-moodboard {
  &__product-wrapper {
    display: flex;
    flex-direction: column;
    background-color: rgba($del-color-pure-black, 0.03);
    padding: 24px;
    text-align: center;
    height: 100%;
    cursor: pointer;

    @include bp-range('del-breakpoint-s', 'del-breakpoint-max-m') {
      padding: 24px 24px 5px;
    }

    @include bp-range('del-breakpoint-m', 'del-breakpoint-max-l') {
      padding: 16.31% 17.1% 5px;
    }

    @include bp('del-breakpoint-l') {
      padding: 16.31% 17.1% 30px;
    }

    &__media {
      display: block;
      position: relative;

      @include bp('del-breakpoint-m') {
        overflow: hidden;

        .del-picture {
          transition: transform 0.7s ease-in-out;
        }

        &:hover {
          .del-picture {
            transform: scale(1.2);
          }
        }
      }
    }

    &__info {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;
      margin-bottom: 12px;
    }

    &__category {
      @include del-font-medium;
      color: var(--brand-07);
      font-size: REM(12px);
      text-transform: uppercase;
      line-height: normal;
    }

    &__cta {
      @include del-font-medium;
      height: auto;
      width: 100%;
      font-size: REM(18px);
      line-height: normal;
      text-decoration: none;
      color: $del-color-text;
      display: block;
      justify-content: center;
    }



    &__price {
      .--list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        list-style: none;
        gap: 4px;
        font-size: REM(18px);
        @include del-font-book;
        color: var(--brand-06);

        .--mainPrice {
          width: 100%;
          padding-bottom: 5px;
        }

        .--info {
          display: flex;
          gap: 4px;
          flex-wrap: wrap;
          justify-content: center;
        }

        .--strikePrice,
        .--percentageDiscount,
        .--labelPrice {
          font-size: REM(12px);
          color: var(--brand-07);
          line-height: 10px;
        }

        .--strikePrice {
          text-decoration: line-through;
        }

        .--percentageDiscount::before {
          content: '(';
        }

        .--percentageDiscount::after {
          content: ')';
        }
      }
    }

    &__old-price {
      color: $del-color-text-60;
      text-decoration: line-through;
      font-size: REM(14px);
      @include del-font-book;
      line-height: 24px;
    }

    &__image-overlay {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      background-color: rgba(0, 0, 0, 0.03);

      @include bp-range('del-breakpoint-s', 'del-breakpoint-max-m') {
        width: 250px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .del-picture__image {
      @include bp-range('del-breakpoint-s', 'del-breakpoint-max-m') {
        width: 250px;
        margin: 0 auto;
      }
    }
  }
}

// secondary ui
.del-product-moodboard {
  &__product-wrapper {
    &.--secondary {
      padding: 8px 8px 25px;
      height: auto;
      width: calc(50% - 10px);

      @include bp-range('del-breakpoint-s', 'del-breakpoint-max-m') {
        padding: 30px 8px 5px;
      }

      @include bp-range('del-breakpoint-m', 'del-breakpoint-max-l') {
        padding: 20px 23px 5px;
      }

      @include bp('del-breakpoint-l') {
        padding: 16px 8.8% 28px;
      }

      .del-product-moodboard__product-wrapper__image-overlay {
        @include bp-range('del-breakpoint-s', 'del-breakpoint-max-m') {
          width: 130px;
        }
      }

      .del-picture__image {
        @include bp-range('del-breakpoint-s', 'del-breakpoint-max-m') {
          width: 130px;
        }
      }

      &.--full {
        @include bp-range('del-breakpoint-m', 'del-breakpoint-max-l') {
          padding: 20px 20px 5px 40px !important;
        }
      }

      .--list .--labelPrice {
        width: 100%;
        @include bp('del-breakpoint-m') {
          width: auto;
        }
      }
    }
  }
}

// full ui
.del-product-moodboard {
  &__product-wrapper {
    &.--full {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 8px;

      @include bp('del-breakpoint-m') {
        padding: 30px 8px 30px 17.1%;
      }
    }

    &__media,
    &__info {
      .--full & {
        width: calc(50% - 5px);
      }
    }
  }
}

.dln-bundle {
  .del-picture {
    max-width: 180px;
  }
}
