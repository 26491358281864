@include bp('del-breakpoint-s') {
  .del-stories {
    &__wrapper {
      padding-top: 50%;
    }

    &__screen {
      &--start {
        & .buttonTap.tapToStart {
          bottom: 60px;
        }
      }
    }
  }

  .swiper-container.stories-slider {
    .swiper-slide {
      &.topAligned {
        align-items: flex-end;
      }

      .del-stories__slide--content {
        max-width: calc(calc(100% - 20%) / 2);
      }
    }

    .swiper-pagination {
      bottom: 30px;
      width: calc(80% + 8px);
    }
  }
}

@include bp-range('del-breakpoint-s', 'del-breakpoint-max-m') {
  .del-stories {
    &__container {
      padding: 0 40px;
    }

    &__wrapper {
      height: 345px;
    }

    &__screen {
      &--content {
        display: block;
      }

      .del-subtitle {
        @include del-font-serif-medium;
        max-width: 438px;
        margin-top: 147px;
        padding-bottom: 0;
      }

      .buttonTap {
        width: 94px;
        left: calc(50% - 47px);
        border-bottom: 2px solid;
        padding: 5px 0;
        bottom: 20px !important;

        &::before {
          width: 32px;
          height: 32px;
          left: calc(50% - 16px);
          border-radius: 50%;
          top: -50px;
        }

        &::after {
          top: -50px;
        }
      }
    }

    .swiper-container.stories-slider {
      .swiper-slide {
        .del-stories__slide--content {
          margin-left: 35px;
          margin-bottom: 68px;

          strong {
            margin-bottom: 9px;
          }
        }
      }

      .swiper-pagination {
        bottom: 20px;
        width: calc(100% - 105px);
      }

      .swiper-footer {
        .swiper-button-close {
          padding: 20px;
        }

        .del-btn-close__icon {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
