.del-sidecard-max-widths {
  max-width: 328px;
  @include bp('del-breakpoint-s') {
    max-width: unset;
  }

  @include bp('del-breakpoint-m') {
    max-width: 500px;
  }

  @include bp('del-breakpoint-l') {
    max-width: 802px;
  }
}

.del-sidecard-aspect-ratio {
  aspect-ratio: 325 / 239;
  width: 325px;

  @include bp('del-breakpoint-s') {
    aspect-ratio: 435 / 320;
    width: 435px;
  }

  @include bp('del-breakpoint-m') {
    aspect-ratio: 500 / 369;
    width: 500px;
  }

  @include bp('del-breakpoint-l') {
    aspect-ratio: 800 / 590;
    width: 800px;
  }
}

.del-sidecard-max-widths--full {
  max-width: 360px;
  @include bp('del-breakpoint-s') {
    max-width: 576px;
  }

  @include bp('del-breakpoint-m') {
    max-width: 664px;
  }

  @include bp('del-breakpoint-l') {
    max-width: 1066px;
  }
}

.del-sidecard-aspect-ratio--full {
  aspect-ratio: 360 / 260;
  width: 360px;

  @include bp('del-breakpoint-s') {
    aspect-ratio: 576 / 370;
    width: 576px;
  }

  @include bp('del-breakpoint-m') {
    aspect-ratio: 664 / 426;
    width: 664px;
  }

  @include bp('del-breakpoint-l') {
    aspect-ratio: 1066 / 684;
    width: 1066px;
  }
}

.del-sidecard {
  display: flex;
  flex-direction: column;
  margin-inline: auto;
  padding: unset;

  @include bp('del-breakpoint-m') {
    flex-direction: row;
    align-items: center;
    max-width: 1400px;

    &.--full-width {
      max-width: 1920px;
    }

    &.--reverse {
      flex-direction: row-reverse;
    }
    &__title-mobile {
      display: none;
    }
  }

  &__title-mobile {
    margin: 0;
    .del-textgroup__title {
      text-align: start;
    }
    body:not(.page-dlgDelonghiPDP) & {
      display: none;
    }
  }

  &__media {
    display: flex;
    justify-content: center;
    margin-inline: auto;
    @extend .del-sidecard-aspect-ratio;
    @extend .del-sidecard-max-widths;
    &.--full-width {
      @extend .del-sidecard-aspect-ratio--full;
      @extend .del-sidecard-max-widths--full;
    }

    @include bp('del-breakpoint-m') {
      width: 57.28571429%; // 802/1400
      &.--full-width {
        width: 55.52083333%; // 1066/1920
      }
    }

    img,
    video {
      display: block;
      max-width: 100%;
      height: auto;
      object-fit: cover;
      @extend .del-sidecard-aspect-ratio;
      &.--full-width {
        @extend .del-sidecard-aspect-ratio--full;
      }
    }
  }

  &__video-mobile {
    @include bp('del-breakpoint-m') {
      display: none !important;
    }
  }

  &__video-desktop {
    display: none !important;
    @include bp('del-breakpoint-m') {
      display: block !important;
    }
  }

  &__texts-wrapper {
    @extend .del-sidecard-max-widths;
    &.--full-width {
      @extend .del-sidecard-max-widths--full;
    }

    margin-inline: auto;
    margin-top: 18px;

    @include bp('del-breakpoint-s') {
      margin-top: 23px;
    }

    @include bp('del-breakpoint-m') {
      margin-top: 0;
      width: 42.71428571%; // 598/1400
      &.--full-width {
        width: 44.47916667%; // 854/1920
      }
    }
    &--main {
      .del-textgroup__title  {
        display: none;

        &:not(.del-textgroup__title--mobile) {
          @include bp('del-breakpoint-m') {
            display: block;
          }
          body:not(.page-dlgDelonghiPDP) & {
            display: block;
          }
        }
      }
    }
  }

  &__texts {
    @include bp('del-breakpoint-m') {
      width: 357px;
      margin-left: auto;
      margin-right: auto;

      &.--reverse {
        margin-left: auto;
        margin-right: auto;
      }
    }
    @include bp('del-breakpoint-l') {
      width: 357px;
      margin-left: 142px;
      margin-right: auto;

      &.--reverse {
        margin-left: auto;
        margin-right: 115px;
      }
    }
  }

  &__texts {
    @include textgroup-shared-texts();
  }
}
