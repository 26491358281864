.del-contentcards {

  &__icon-close {
    position: absolute;
    top: 0;
    right: 0;
    $size-mobile: 24px;
    width: $size-mobile;
    height: $size-mobile;
    background-position: center;
    background-repeat: no-repeat;
    background-size: $size-mobile $size-mobile;
  }

  &__icon-plus {
    $size-mobile: 40px;
    flex: 0 0 $size-mobile;
    width: $size-mobile;
    height: $size-mobile;
    margin-left: 16px;

    @include bp('del-breakpoint-l') {
      margin-left: 0;
      margin-bottom: 24px;
    }

    &--inner {
      transform: scale(0,0);
      transition: transform 0.3s;
      margin: 0;
    }
  }
}
