.del-stripe__sticky {
  position: fixed;
  top: 0;
  transform: translateY(-100px);
  transition: transform .3s ease-in-out;
  width: 100%;
  max-width: 1920px;
  background-color: $del-color-white;
  z-index: 4;
  border-bottom: 1px solid $del-color-grey;

  &.--active {
    transform: translateY(61px);

    @include bp('del-breakpoint-m') {
      transform: translateY(93px);
    }
  }

  @include bp('del-breakpoint-m') {
    box-shadow: 0 4px 2px -2px $del-color-grey;
    border-bottom: 0;
  }

  &__wrapper {
    max-width: 1400px;
    margin: 0 auto;
    width: 100%;
    padding: 17px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @include bp('del-breakpoint-s') {
      padding: 8px 28px;
    }

    @include bp('del-breakpoint-xl') {
      padding: 8px 0px;
    }
  }

  .del-image {
    display: none;
    @include bp('del-breakpoint-s') {
      display: block;
      width: 75px;
      height: 85px;
      margin-right: 5px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__title {
    @include del-font-heavy;
    font-size: REM(16px);
    line-height: REM(19px);
    color: $del-color-text;

    @include bp('del-breakpoint-s') {
      font-size: REM(20px);
      line-height: REM(24px);
      padding-left: 30px;
    }
  }

  &__cta {
    min-width: 144px;
    margin-left: auto;
    padding-top: 10px;
    padding-bottom: 10px;

    a {
      @include del-font-heavy;
      text-decoration: none;
      font-size: 16px;
      line-height: 24px;
      color: white;

      @include bp('del-breakpoint-s') {
        font-size: 18px;
      }

    }
  }

}

.del-header--hidden .del-stripe__sticky.--active {
  @include bp('del-breakpoint-m') {
    transform: translateY(0px);
  }
}
