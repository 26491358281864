.del-pdp__hr {
  margin: 15px 0;
  border: 0;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
}

.dln-product-accordion {
  &--secondary.del-button {
    border: 2px solid #000;
    border-radius: 3px;
  }

  &--open {
    cursor: pointer;
  }

  &__content {
    margin-top: REM(10px);
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;

    .dln-in-accordion-btn {
      margin-top: REM(15px);
    }

    p,
    li {
      @include del-font-book;
      font-size: REM(16px);
      line-height: REM(22px);

      strong {
        @include del-font-medium;
        display: block;
        font-weight: 700;
        margin-top: REM(15px);
      }
    }

    &--box-description {
      &-title {
        margin-bottom: REM(2px);
      }
    }

    &--long-description {
      margin-top: REM(10px);

      &-title {
        margin-bottom: REM(2px);
      }

      p {
        font-size: REM(13.5px);
        line-height: REM(21px);
      }
    }
  }

  // Defined like this to increase CSS priority
  &.dln-product-accordion--close {
    text-align: center;
    width: 100%;

    span {
      pointer-events: none; // Prevent click on span, allow only click event on parent button (check this)
    }
  }

  // Defined like this to increase CSS priority
  &.dln-product-accordion--hidden {
    display: none;
  }
}
