.del-miniCart.dln-miniCart {
  left: auto;
  right: 26px;
  top: 70px;
  position: fixed;

  .dln-miniCart__content {
    min-height: 200px;
    max-height: 289px;
    overflow-y: auto;

    .dln-icn:before, [class^=dln-icn]:before {
      font-family: var(--fontNameIcons);
    }
  }

  [class^=dln-icn]:before {
    font-family: var(--fontNameIcons);
  }
}
