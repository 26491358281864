.del-padding {
  width: 100%;
  background-color: unset;

  &.del-padding--xlarge {
    height: 80px;

    @include bp('del-breakpoint-m') {
      height: 160px;
    }
  }

  &.del-padding--large {
    height: 40px;

    @include bp('del-breakpoint-m') {
      height: 80px;
    }
  }

  &.del-padding--medium {
    height: 24px;

    @include bp('del-breakpoint-m') {
      height: 40px;
    }
  }

  &.del-padding--small {
    height: 16px;

    @include bp('del-breakpoint-m') {
      height: 24px;
    }
  }

  &.del-padding--xsmall {
    height: 8px;

    @include bp('del-breakpoint-m') {
      height: 16px;
    }
  }
}