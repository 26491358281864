.del-awards-carousel {
  &__wrapper {
    background-color: $del-color-white-smoke;

    .del-awards-carousel__swiper-container {
      /* no & operator in order to avoid specificity issue */
      max-width: 900px;
      padding: 50px 0 80px;
    }

    .del-awards-carousel__pagination.del-awards-carousel__pagination {
      /* no & operator in order to avoid specificity issue */
      bottom: 35px;
    }
  }

  &__slide {
    text-align: center;
    padding: 0 15px;
  }

  &__image {
    display: block;
    margin: 0 auto;
  }

  &__title,
  &__description {
    line-height: 24px;
    padding: 0 15px;
  }

  &__title {
    @include del-font-medium;
    font-size: toRem(24px);
    margin-top: 15px;
  }

  &__description {
    @include del-font-book;
    font-size: toRem(16px);
  }
}
