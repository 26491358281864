@include bp('del-breakpoint-l') {
  html[dir='rtl'] {
    .del-hero-banner {
      &__texts-wrapper {
        &.--middle-left {
          justify-content: flex-end;
          .del-hero-banner__texts {
            width: 35%;

            .del-textgroup__content {
              max-width: 100%;
            }
          }
        }
        &.--middle-right {
          .del-hero-banner__texts {
            width: 100%;
          }
        }
      }
    }
  }
}
