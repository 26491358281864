.del-promoslider {
  .del-hero-banner__mask {
    position: absolute;
    z-index: 1;
    display: block;
  }

  &__title {
    @include del-font-medium;
    font-size: REM(20px);
    line-height: normal;
    letter-spacing: 2px;
    color: $del-color-text;
    padding-block: 22px;
    text-align: center;

    @include bp('del-breakpoint-l') {
      font-size: REM(22px);
      padding-block: 38px;
    }
  }

  // overrading text size for promo slider only
  .del-hero-banner__texts-wrapper {
    position: absolute;
    padding-block: 0;
    bottom: 28px;
    z-index: 2;
    width: 100%;

    .del-textgroup {
      display: flex;
      flex-direction: column;
      gap: 10px;

      @include bp('del-breakpoint-xl') {
        gap: 21px;
      }

      &--center {
        @include bp('del-breakpoint-s') {
          max-width: 555px !important;
          margin-inline: auto;
          padding: 0;
        }

        @include bp('del-breakpoint-m') {
          max-width: 666px !important;
        }

        @include bp('del-breakpoint-xl') {
          max-width: 763px !important;
        }
      }

      .del-textgroup__texts {
        display: flex;
        flex-direction: column;
        gap: 15px;
        @include bp('del-breakpoint-s') {
          gap: 10px;
        }
        @include bp('del-breakpoint-xl') {
          gap: 12px;
        }
      }

      .del-textgroup__title,
      .del-textgroup__content {
        @include del-font-medium;
        margin-bottom: 0;
      }

      .del-textgroup__title,
      .del-textgroup__title * {
        font-size: REM(26px);
        line-height: 28px;

        @include bp('del-breakpoint-xl') {
          font-size: REM(40px);
          line-height: 42px;
        }
      }

      .del-textgroup__content,
      .del-textgroup__content * {
        font-size: REM(16px);
        line-height: 19px;
        @include bp('del-breakpoint-xl') {
          font-size: REM(18px);
          line-height: 21px;
          max-width: unset;
        }
      }

      .del-textgroup__title *,
      .del-textgroup__content * {
        margin: 0;
      }


      .del-textgroup__cta--medium {
        font-size: REM(18px);
        line-height: 19px;
        height: auto;
      }
    }
  }
}
