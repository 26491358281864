@mixin textgroup-title--divider {
  @include del-font-medium;
  font-size: toRem(16px);
  letter-spacing: 3.2px;
  line-height: 22.4px;

  @include bp('del-breakpoint-s') {
    font-size: toRem(21px);
    letter-spacing: 6.3px;
    line-height: 29.4px;
  }

  @include bp('del-breakpoint-l') {
    font-size: toRem(24px);
    letter-spacing: 9.6px;
    line-height: 33.6px;
  }
}

.textgroup-title--divider {
  @include textgroup-title--divider;
}

@mixin textgroup--divider {
  .del-textgroup {
    &__title {
      @extend .textgroup-title--divider;
      p {
        @extend .textgroup-title--divider;
      }
    }
  }
}
