.del-header {
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 5;
  width: 100%;
  max-height: 100vh;
  background-color: transparent;
  color: $del-color-text;
  overflow-y: hidden;

  @include bp('del-breakpoint-m') {
    overflow-y: unset;
  }

  &--hidden {
    @include bp('del-breakpoint-m') {
      animation: del-header-animation-hide 0.3s ease;
      top: -93px;
    }
  }

  &--visible {
    @include bp('del-breakpoint-m') {
      animation: del-header-animation-show 0.3s ease;
    }
  }

  a,
  a:link,
  a:visited {
    text-decoration: none;
    color: inherit;
  }

  ul {
    list-style: none;

    li {
      cursor: pointer;
    }
  }

  .del-icon.icon-select-up {
    transform: rotate(90deg);
  }
}
