.del-input-checkbox {
  appearance: none;
  position:relative;
  height: 20px;
  width: 20px;
  border: 1px solid $del-color-black;
  border-radius: 3px;
  cursor: pointer;
  transition: $del-transition-checkbox-check;

  &::after {
    content: '';
    position: absolute;
    opacity: 0;
    background-image: url("#{$pathAssets}/images/delonghiIcons/ok-black.svg");
    background-size: 17px 17px;
    background-position: center center;
    background-repeat: no-repeat;
    width: 17px;
    height: 17px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:checked::after {
    opacity: 1;
  }

  &[disabled] {
    cursor: not-allowed;
    background-color: $del-color-grey;
  }
}