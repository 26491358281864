.del-keyfeatures {
  .swiper {
    overflow-x: hidden;

    .swiper-wrapper {

      &.--count-1 {
        justify-content: center;
      }

      &.--count-2 {
        @media (min-width: 672px) { // tweekpoint
          justify-content: center;
        }
      }

      &.--count-3 {
        @include bp('del-breakpoint-s') {
          transform: translateX(24px); // to prevent layout shift after swiper initialization
        }
        @media (min-width: 996px) { // tweekpoint
          justify-content: center;
          transform: translateX(0) !important; // remove from del-breakpoint-m above and override swiper one
        }
      }

      &.--count-4 {
        @include bp('del-breakpoint-s') {
          transform: translateX(24px); // to prevent layout shift after swiper initialization
        }
        @include bp('del-breakpoint-m') {
          transform: translateX(62px); // to prevent layout shift after swiper initialization
        }
        @media (min-width: 1324px) { // tweekpoint
          justify-content: center;
          transform: translateX(0) !important; // remove from del-breakpoint-m above and override swiper one
        }
      }
    }

    .swiper-slide {
      height: auto;
      max-width: 100%;
      margin-inline: auto;

      @media (min-width: 375px) { // tweekpoint
        margin-inline: 16px;
        max-width: 304px;
      }

      @include bp('del-breakpoint-m') {
        max-width: 284px;
      }
  
      @include bp('del-breakpoint-m') {
        margin-inline: 8px;
      }
  
      @include bp('del-breakpoint-xl') {
        margin-inline: 24px;
        max-width: 306px;
      }


      .del-keyfeatures-tile {
        height: 100%;
        max-width: 304px;
        margin-inline: auto;

        @include bp('del-breakpoint-s') {
          max-width: unset;
        }
      }
    }
  }
}
