.del-swiper-bullet-pagination {
  display: flex;
  justify-content: center;
  align-items: center;

  .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    background-color: $del-color-text-60;
    margin: 0 9px;

    &-active {
      width: 12px;
      height: 12px;
      background-color: $del-color-text;
    }
  }
}
