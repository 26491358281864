.del-moodboard-card__mask--bottom {
  bottom: 0;
  left: 4px;
  right: 4px;
  height: 0;

  @include bp('del-breakpoint-xl') {
    left: 0;
    right: 0;
  }
}

.del-moodboard-card__mask {
  position: absolute;
  visibility: visible;
  &--bottom--white,
  &--bottom--black {
    @extend .del-moodboard-card__mask--bottom;
  }
  &--bottom--white {
    background: linear-gradient(to top, $del-color-white-mask, transparent);
  }
  &--bottom--black {
    background: linear-gradient(to top, $del-color-black-mask, transparent);
  }
  &--bottom--percent30 {
    height: 30%;
  }
  &--bottom--percent40 {
    height: 40%;
  }
  &--bottom--percent50 {
    height: 50%;
  }
  &--bottom--percent60 {
    height: 70%;
  }
  &--bottom--percent70 {
    height: 70%;
  }
}
