.del-moodboard-card {
  position: relative;
  z-index: 0;
  transform: scale(1);
  transition: z-index 0.2s step-end, transform 0.2s linear;

  @include bp('del-breakpoint-xl') {
    &:hover {
      z-index: 1;
      transform: scale(1.1);
      transition: z-index 0.2s step-start, transform 0.2s linear;
    }
  }

  img {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;

    aspect-ratio: 330 / 400;

    @include bp('del-breakpoint-s') {
      aspect-ratio: 340 / 411;
    }

    @include bp('del-breakpoint-m') {
      aspect-ratio: 438 / 530;
    }

    @include bp('del-breakpoint-xl') {
      aspect-ratio: 960 / 560;
      &.--three-tiles {
        aspect-ratio: 640 / 560;
      }
      &.--four-tiles {
        aspect-ratio: 480 / 560;
      }
    }
  }
}
