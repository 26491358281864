.del-header {
  &__category {
    display: block;
    width: 100%;
    height: calc(100vh - 61px);
    overflow-y: hidden;

    @include bp('del-breakpoint-l') {
      background-color: transparent;
    }

    &--hidden {
      display: none;
    }
  }

  &__filler {
    position: relative;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background-color: $del-color-white;
    box-shadow: 1px 0px 10px $del-color-text;
    animation: del-header-animation-categoryScreen-slide-down 0.3s ease;

    @include bp('del-breakpoint-l') {
      height: unset;
      overflow-y: hidden;
    }
  }

  &__close-header {
    position: absolute;
    top: 10px;
    right: 0;
    font-size: toRem(24px);
    padding: 5px;
    cursor: pointer;
    z-index: 1;
    display: none;
    border: none;
    background: none;

    @include bp('del-breakpoint-l') {
      display: block;
    }
  }

  &__subscreen {
    width: 100%;
    max-width: 1400px;
    height: 100%;
    position: relative;

    @include bp('del-breakpoint-l') {
      overflow-y: hidden;
    }
  }

  &__linkviewall {
    display: none;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 48px;
    background-color: $del-color-light-grey;

    @include bp('del-breakpoint-l') {
      display: flex;
    }

    span,
    a {
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 1400px;
      padding: 0 16px;
      @include del-font-medium;
      font-size: REM(17px);

      .del-icon {
        $size: 14px;
        width: $size;
        height: $size;
      }
    }
  }

  .del-category {
    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 22px 40px;
      border-bottom: 1px solid $del-color-light-grey;

      @include bp('del-breakpoint-l') {
        display: none;
      }

      &--arrow {
        width: 19px;

        .del-icon {
          $size: 19px;
          width: $size;
          height: $size;
        }

        .del-title__linkviewall {
          display: none;
          @include del-font-medium;
          font-size: toRem(21px);
          line-height: 21px;
          text-align: right;
          opacity: 0.6;

          span {
            $size: 16px;
            width: $size;
            height: $size;
            margin: 0 5px;
          }
        }
      }

      &--text {
        @include del-font-medium;
        font-size: REM(20px);
        width: 100%;
        text-align: center;
      }
    }

    &__linkviewall {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 20px 27px 20px 40px;
      @include del-font-medium;
      font-size: REM(20px);
      background-color: $del-color-light-grey;

      @include bp('del-breakpoint-l') {
        display: none;
        font-size: REM(17px);
      }

      a {
        width: 100%;
        display: flex;
        justify-content: space-between;
        @include del-font-medium;
      }

      span {
        $size: 16px;
        width: $size;
        height: $size;
        margin: 0 5px;
      }
    }

    &__body {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      overflow-x: hidden;

      @include bp('del-breakpoint-l') {
        flex-direction: row;
        justify-content: space-between;
      }
    }

    &__navbar {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 24px 16px;

      @include bp('del-breakpoint-l') {
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;
        border-bottom: 1px solid $del-color-light-grey;
        height: 48px;
        padding: 0;
      }

      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid $del-color-light-grey;
        font-size: toRem(18px);
        padding: 28px 24px;
        color: $del-color-text;

        &:first-of-type {
          padding-top: 4px;
        }

        @include bp('del-breakpoint-l') {
          display: block;
          width: unset;
          height: unset;
          margin: 0 24px;
          padding: 9px 0;
          border: none;
          font-size: toRem(18px);
          line-height: 18px;
          color: $del-color-text-60;

          &:first-of-type {
            padding: 9px 0;
          }

          &.del-option--selected {
            padding: 7px 0;
            color: $del-color-black;
            border-bottom: 2px solid $del-color-bright-blue;
          }
        }

        &:last-child {
          border: none;

          @include bp('del-breakpoint-l') {
            &.del-option--selected {
              padding: 7px 0;
              color: $del-color-black;
              border-bottom: 2px solid $del-color-bright-blue;
            }
          }
        }

        button {
          display: flex;
          align-items: center;
          border: none;
          background: none;
          color: inherit;
          font: inherit;
        }

        span {
          @include del-font-medium;
        }

        .del-icon {
          $size: 20px;
          width: $size;
          height: $size;

          @include bp('del-breakpoint-l') {
            display: none;
          }
        }
      }
    }

    &__column {
      display: flex;
      flex-direction: column;
      padding-bottom: 19px;
      flex-grow: 1;

      @include bp('del-breakpoint-l') {
        flex-direction: row;
        flex-wrap: wrap;
        width: unset;
        min-width: 25%;
        max-width: 50%;
      }

      .del-navnode {
        @include bp('del-breakpoint-l') {
          width: 50%;
        }

        &:only-child {
          width: 100%;

          .del-navnode__title {
            display: flex;
          }
        }
      }
    }

    &__subcategory-menu {
      @include bp('del-breakpoint-l') {
        height: unset;
      }
    }
  }

  .del-navnode {
    width: 100%;
    padding: 0 20px 0 40px;

    &:not(:first-child) {
      margin-top: 19px;
      padding-top: 2px;
      border-top: 1px solid $del-color-light-grey;
      @include bp('del-breakpoint-l') {
        margin-top: unset;
        padding-top: unset;
        border: 0;
      }
    }

    @include bp('del-breakpoint-l') {
      width: unset;

      &:nth-child(n + 3) {
        padding-top: 19px;
      }
    }

    &__title {
      display: flex;
      align-items: center;
      @include del-font-medium;
      font-size: REM(18px);
      padding: 28px 0 12px 0;

      @include bp('del-breakpoint-l') {
        font-size: REM(20px);
      }
    }

    &__list {
      width: 100%;

      li {
        display: flex;
        align-items: center;
        width: 100%;

        @include bp('del-breakpoint-l') {
          min-height: 44px;
          max-height: 170px;
        }

        a {
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;
          @include del-font-light;
          font-size: REM(18px);
          padding: 12px 0 11px 0;

          &:hover {
            text-decoration: underline;
          }

          &:focus {
            outline: 1px solid $del-color-green;
          }

          @include bp('del-breakpoint-l') {
            padding: 12px 0 13px 0;
            font-size: REM(17px);
          }
        }
      }
    }

    &:last-child li:last-of-type {
      border: none;
    }
  }

  .del-highlights {
    padding-bottom: 13px;
    border-top: 1px solid $del-color-light-grey;
    flex-grow: 0;

    @include bp('del-breakpoint-l') {
      width: unset;

      padding-top: 0;
      margin-top: 24px;
      border: none;
    }

    &__title {
      @include bp('del-breakpoint-l') {
        padding-top: 4px;
      }
    }

    &__body {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      @include bp('del-breakpoint-l') {
        height: 100%;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
      }
    }

    &__body-inner {
      width: 100%;

      @include bp('del-breakpoint-l') {
        width: 330px;
      }
    }

    &__body-separator {
      display: none;

      @include bp('del-breakpoint-l') {
        display: block;
        width: 3px;
        height: 100%;
        background-color: $del-color-light-grey;
      }
    }

    .del-navnode {
      padding-bottom: 13px;
      width: 100%;
      border-bottom: 1px solid $del-color-light-grey;

      @include bp('del-breakpoint-l') {
        width: 330px;
        border: none;
      }

      &__list {
        width: 100%;
      }
    }
  }

  .del-highlights__media-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    width: 100%;
    padding-top: 28px;

    @include bp('del-breakpoint-s') {
      flex-direction: row;
      padding-left: 40px;
      gap: 20px;
      align-items: flex-start;
    }

    @include bp('del-breakpoint-l') {
      padding-top: 0;
      padding-left: 12px;
      margin-top: 20px;
    }

    picture,
    img {
      width: 330px;
      max-width: 330px;
      height: 200px;
      object-fit: cover;

      @include bp('del-breakpoint-s') {
        width: 330px;
        height: 330px;

        &.--double {
          width: 680px;
          max-width: 680px;
        }
      }
    }

    picture {
      margin: 0;
      img {
        object-fit: contain;
      }
    }

    .swiper-container,
    .swiper-slide {
      margin: 0;
      max-width: 330px;
    }

    .del-imagecarousel__button {
      position: absolute;
      top: 94px;
      width: 12px;
      height: 12px;

      &:after {
        content: '';
      }

      &.swiper-button-prev {
        transform: rotate(-90deg);
      }

      &.swiper-button-next {
        transform: rotate(90deg);
      }

      @include bp('del-breakpoint-s') {
        top: 159px;
      }
    }

    .del-imagelink {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 17px;

      &__product {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        width: 100%;
      }

      &__link,
      &__product-name,
      &__product-price {
        display: grid;
        grid-template-columns: auto 1fr;
        color: $del-color-pure-black;

        &__linkName {
          font-size: REM(17px);
          @include del-font-medium;
          line-height: REM(20px);
        }

        .del-icon {
          width: 14px;
          height: REM(20px);
          margin-left: 10px;
        }
      }

      &__link {
        max-width: 330px;
      }

      &__product-price {
        @include del-font-book;
        gap: 20px;
      }

      &__product-oldPrice {
        color: $del-color-text-60;
        text-decoration: line-through;
      }
    }
  }

  .del-imagecarousel--triple {
    display: none;
    opacity: 0;
    transition: opacity 0.5s ease;
    padding-bottom: 84px;

    @include bp('del-breakpoint-l') {
      display: block;
    }

    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;

      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }

      img {
        display: block;
        width: 100%;
        max-width: 448px;
        aspect-ratio: 448 / 328;
        object-fit: cover;
      }
    }

    .swiper-button {
      display: flex;
      justify-content: center;
      align-items: center;
      top: 144px;
      $size: 40px;
      width: $size;
      height: $size;
      border: 1px solid $del-color-text;
      border-radius: $size / 2;
      background-color: rgba($del-color-white, 0.8);

      &.swiper-button-prev {
        left: 8px;
      }

      &.swiper-button-next {
        right: 8px;
      }
    }

    .swiper-pagination {
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: 32px;

      &-bullet {
        margin: 0 10px !important;
        background-color: $del-color-text-60 !important;
        opacity: 1;

        &-active {
          $size: 12px;
          width: $size !important;
          height: $size !important;
          background-color: $del-color-text !important;
        }
      }
    }
  }

  /* Hides the element for Mobile and Tablet views only */
  .del-mobile-hidden {
    display: none;

    @include bp('del-breakpoint-l') {
      display: flex;
    }
  }
}
