.del-contentcards {

  &__background {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: opacity 1s ease-in-out;

    &--mobile {
      @include bp('del-breakpoint-s') {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @include bp('del-breakpoint-s') {
        display: block;
      }
    }

    &--card {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center, 75% 50%, center;
      opacity: 0;
    }

    &--opaque {
      opacity: 1;
    }

    &--transparent {
      opacity: 0;
    }
  }
}

[dir="rtl"] {
  .del-contentcards {
    &__background {
      &--card {
        background-position: center, 25% 50%, center;
      }
    }
  }
}
