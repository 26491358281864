.del-videoFull {
  position: relative;
  aspect-ratio: 16/9;

  &__mask {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: $del-color-black-light-mask;
    z-index: 1;
    height: 100%;
  }

  &__cover {
    position: relative;
    aspect-ratio: 16/9;

    img {
      display: block;
      max-width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  &.--ready {
    .del-videoFull__play-button {
      opacity: 1;
      pointer-events: all;
      cursor: pointer;
    }
  }

  &__play-button {
    position: absolute;
    inset: 0;
    background: none;
    border: none;
    opacity: 0.5;
    pointer-events: none;
    z-index: 2;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 67px;
      height: 67px;

      @include bp('del-breakpoint-s') {
        width: 112px;
        height: 112px;
      }
    }
  }

  &__texts {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    gap: 10px;
    padding-inline: 3px;
    text-align: center;

    @include bp('del-breakpoint-l') {
      position: absolute;
      top: 50%;
      transform: translateY(100%);
      gap: 0;
      z-index: 3;
    }

    .del-videoFull__title,
    .del-videoFull__title p {
      @include del-font-book;
      font-size: 25px;
      color: $del-color-pure-black;
      line-height: 37px;
      @include bp('del-breakpoint-l') {
        font-size: 37px;
        color: $del-color-white;
        margin-top: 10px;
      }
    }

    .del-videoFull__subtitle,
    .del-videoFull__subtitle p {
      @include del-font-demi;
      font-size: 12px;
      color: $del-color-pure-black;
      letter-spacing: 1.714px;
      opacity: 0.8;

      @include bp('del-breakpoint-l') {
        font-size: 14px;
        letter-spacing: 2px;
        color: $del-color-grey;
      }
    }
  }

  &__player-container {
    position: absolute;
    inset: 0;
    z-index: -1;
    opacity: 0;

    &.--no-cover {
      opacity: 1;
    }

    &:hover {
      .del-videoFull__close-button {
        opacity: 1;
        transform: translateY(0);
      }
    }

    &.--visible {
      z-index: 3;
      opacity: 1;
    }
  }

  .plyr--video {
    aspect-ratio: 16/9;
  }

  .plyr--paused + .del-videoFull__close-button {
    opacity: 1 !important;
    transform: translateY(0) !important;
  }

  &__close-button {
    position: absolute !important;
    top: 0;
    right: 0;
    z-index: 50;
    width: 3em;
    height: 3em;
    opacity: 0;
    transform: translateY(-100%);
    transition: all 0.4s ease;

    &:hover {
      background-color: var(--plyr-accent-color);
    }

    .del-btn-close__icon {
      @include del-button--plus--bw('white', '45', 1);
    }
  }

  &__safari-overlay {
    display: none;
    position: absolute;
    padding-right: 100%;
    padding-top: 35%;
    z-index: 10;

    &.active {
      display: block;
    }
  }
}
