#smarteditoverlay {
  z-index: 1001!important;
}

[data-smartedit-component-id="DELFooterBottomLinksSlot"] {
  .se-storefront-component {
    min-height: unset !important;
  }
}

[data-smartedit-component-id="DELFooterNewsletterSlot"] {
  width: 100% !important;
}
