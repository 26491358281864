.del-hero-banner {
  &__texts-wrapper {
    &.--font-serif {
      .del-textgroup__title,
      .del-textgroup__title * {
        @include del-font-serif-medium;

        strong {
          @include del-font-serif-demi;
        }
      }
    }

    @include bp('del-breakpoint-l') {
      position: absolute;
      z-index: 2;
      inset: 0;
      display: flex;

      .del-hero-banner__texts {
        width: 100%;
      }

      &.--middle-left,
      &.--top-center,
      &.--middle-center,
      &.--bottom-center {
        .del-textgroup__content {
          max-width: 830px;
        }
      }

      &.--middle-left {
        align-items: center;
        justify-content: flex-start;
      }

      &.--middle-right {
        align-items: center;
        justify-content: flex-end;
        .del-hero-banner__texts {
          width: 35%;
        }
      }

      &.--top-center,
      &.--middle-center,
      &.--bottom-center {
        justify-content: center;
        text-align: center;
        .del-textgroup__content,
        .del-textgroup__countdown {
          margin-inline: auto;
        }
      }

      &.--middle-center {
        align-items: center;
      }

      &.--top-center {
        align-items: flex-start;
        top: 5%;
      }

      &.--bottom-center {
        align-items: flex-end;
        bottom: 5%;
      }
    }
  }

  &__texts-wrapper {
    padding-top: 16px;
    padding-bottom: 25px;

    @include bp('del-breakpoint-s') {
      padding-top: 23px;
    }

    @include bp('del-breakpoint-l') {
      padding-block: 0;
    }

    .del-textgroup__subtitle {
      @include del-font-medium;
      font-size: REM(12px);
      opacity: 0.6;
      letter-spacing: 2px;
      margin-top: 0;
      margin-bottom: 12px;

      @include bp('del-breakpoint-s') {
        font-size: REM(14px);
      }

      @include bp('del-breakpoint-l') {
        margin-bottom: 15px;
      }
    }

    .del-textgroup__title,
    .del-textgroup__title * {
      @include del-font-medium;
      font-size: REM(35px);
      line-height: 1;
      margin-top: 0;
      margin-bottom: 24px;
      * {
        margin: 0!important;
      }
      @include bp('del-breakpoint-l') {
        font-size: REM(68px);
        margin-bottom: 30px;
      }
    }

    .del-textgroup__countdown {
      margin-top: -5px;
      margin-bottom: 25px;
    }

    .del-textgroup__separator {
      margin-top: -12px;
      margin-bottom: 15px;
    }

    .del-textgroup__content,
    .del-textgroup__content * {
      @include del-font-book;
      font-size: REM(18px);
      line-height: 1.17;
      margin-top: 0;
      margin-bottom: 29px;
      @include bp('del-breakpoint-l') {
        font-size: REM(23px);
        line-height: 1.09;
        margin-bottom: 34px;
      }
    }

    .del-textgroup__cta {
      margin-top: 0;
    }
  }
}
