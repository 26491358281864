.del-product-info__energy-label {
  display: flex;
  align-items: center;
  gap: 10px;

  .fiche {
    @include del-font-book;
    font-size: REM(14px);
    color: $del-color-text-40;
    text-decoration: none;
  }

  img {
    display: block;
  }
}
