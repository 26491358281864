.del-hero-banner__mask--cover {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.del-hero-banner__mask--left {
  top: 0;
  bottom: 0;
  left: 0;
  width: 50%;
}
.del-hero-banner__mask--right {
  top: 0;
  bottom: 0;
  right: 0;
  width: 50%;
}
.del-hero-banner__mask--top {
  top: 0;
  left: 0;
  right: 0;
  height: 50%;
}
.del-hero-banner__mask--bottom {
  bottom: 0;
  left: 0;
  right: 0;
  height: 50%;
}

.del-hero-banner {
  position: relative;
  &__mask {
    display: none;
    @include bp('del-breakpoint-l') {
      position: absolute;
      z-index: 1;
      display: block;
    }
    &--cover--white,
    &--cover--black,
    &--cover--grey,
    &--cover--blue {
      @extend .del-hero-banner__mask--cover;
    }
    &--cover--white {
      background-color: $del-color-white-mask;
    }
    &--cover--black {
      background-color: $del-color-black-mask;
    }
    &--cover--grey {
      background-color: $del-color-grey-mask;
    }
    &--cover--blue {
      background-color: $del-color-blue-mask;
    }
    &--left--white,
    &--left--black,
    &--left--grey,
    &--left--blue {
      @extend .del-hero-banner__mask--left;
    }
    &--left--white {
      background: linear-gradient(to right, $del-color-white-mask, transparent);
    }
    &--left--black {
      background: linear-gradient(to right, $del-color-black-mask, transparent);
    }
    &--left--grey {
      background: linear-gradient(to right, $del-color-grey-mask, transparent);
    }
    &--left--blue {
      background: linear-gradient(to right, $del-color-blue-mask, transparent);
    }

    &--right--white,
    &--right--black,
    &--right--grey,
    &--right--blue {
      @extend .del-hero-banner__mask--right;
    }
    &--right--white {
      background: linear-gradient(to left, $del-color-white-mask, transparent);
    }
    &--right--black {
      background: linear-gradient(to left, $del-color-black-mask, transparent);
    }
    &--right--grey {
      background: linear-gradient(to left, $del-color-grey-mask, transparent);
    }
    &--right--blue {
      background: linear-gradient(to left, $del-color-blue-mask, transparent);
    }

    &--top--white,
    &--top--black,
    &--top--grey,
    &--top--blue,
    &--header--white,
    &--header--black,
    &--header--grey,
    &--header--blue {
      @extend .del-hero-banner__mask--top;
    }
    &--top--white,
    &--header--white {
      background: linear-gradient(
        to bottom,
        $del-color-white-mask,
        transparent
      );
    }
    &--top--black,
    &--header--black {
      background: linear-gradient(
        to bottom,
        $del-color-black-mask,
        transparent
      );
    }
    &--top--grey,
    &--header--grey {
      background: linear-gradient(to bottom, $del-color-grey-mask, transparent);
    }
    &--top--blue,
    &--header--blue {
      background: linear-gradient(to bottom, $del-color-blue-mask, transparent);
    }

    &--header--white,
    &--header--black,
    &--header--grey,
    &--header--blue {
      height: 35%;
    }

    &--bottom--white,
    &--bottom--black,
    &--bottom--grey,
    &--bottom--blue {
      @extend .del-hero-banner__mask--bottom;
    }
    &--bottom--white {
      background: linear-gradient(to top, $del-color-white-mask, transparent);
    }
    &--bottom--black {
      background: linear-gradient(to top, $del-color-black-mask, transparent);
    }
    &--bottom--grey {
      background: linear-gradient(to top, $del-color-grey-mask, transparent);
    }
    &--bottom--blue {
      background: linear-gradient(to top, $del-color-blue-mask, transparent);
    }
  }
}
